import React, { useEffect } from 'react';

const useSessionStorage = (key: string, initialValue: unknown) => {
  const [value, setValue] = React.useState(() => {
    const storedValue = sessionStorage.getItem(key);
    try {
      // Parse if there's a stored value
      return storedValue !== null
        ? JSON.parse(storedValue)
        : typeof initialValue === 'function'
          ? initialValue()
          : initialValue;
    } catch (error) {
      console.error(`Error parsing sessionStorage for key "${key}":`, error);
      return typeof initialValue === 'function' ? initialValue() : initialValue;
    }
  });

  useEffect(() => {
    if (value === null || value === undefined) {
      // Remove the key if the value is null or undefined
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;

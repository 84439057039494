'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';

import { cn } from 'utils/helpers';
import { Button, buttonVariants } from 'components/ui/button';
import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';

interface DatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  date: Date[] | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date[] | undefined>>;
}

export function DatePickerWithRange({
  className,
  date,
  setDate,
}: DatePickerWithRangeProps) {
  const setSortedDate = (dateList?: Date[]) => {
    dateList?.sort((dateA: Date, dateB: Date) => {
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });
    setDate(dateList);
  };

  const getFormattedDate = (dateObj: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);

    return formattedDate;
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'overflow-hidden w-full justify-between text-left font-normal !bg-[#32395E] !text-white bg-opacity-100 py-3 px-3 text-sm rounded-full border font-sofia-light',
              !date && 'text-muted-foreground',
            )}
            style={{ border: '1px solid transparent' }}
          >
            {/* Commented due to not used in UI */}
            {/* <CalendarIcon className="mr-2 h-4 w-4" /> */}
            <div className="truncate">
              {date?.[0] ? (
                date?.map(
                  (dateObj, index) =>
                    `${getFormattedDate(dateObj)}${index !== date.length - 1 ? ',' : ''} `,
                )
              ) : (
                <span className="text-[#EBDFFF] opacity-50 text-sm">
                  DD/MM/YYYY
                </span>
              )}
            </div>
            <div className={'scale-50 opacity-50 mr-0'}>
              <ChevronDown />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 border-none">
          <Calendar
            initialFocus
            mode="multiple"
            selected={date}
            onSelect={setSortedDate}
            numberOfMonths={1}
            className="bg-slate-800 text-white"
            classNames={{
              months:
                'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
              month: 'space-y-4',
              caption: 'flex justify-center pt-1 relative items-center',
              caption_label: 'text-sm font-medium',
              nav: 'space-x-1 flex items-center',
              nav_button: cn(
                buttonVariants({ variant: 'outline' }),
                'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
              ),
              nav_button_previous: 'absolute left-1',
              nav_button_next: 'absolute right-1',
              table: 'w-full border-collapse space-y-1',
              head_row: 'flex',
              head_cell:
                'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
              row: 'flex w-full mt-2',
              cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-white-400/50 [&:has([aria-selected])]:bg-white-400 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
              day: cn(
                buttonVariants({ variant: 'ghost' }),
                'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
              ),
              day_range_end: 'day-range-end',
              day_selected:
                'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
              day_today: 'bg-white bg-opacity-10 text-white',
              day_outside:
                'day-outside text-white opacity-50 aria-selected:bg-white-400/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
              day_disabled: 'text-muted-foreground opacity-50',
              day_range_middle:
                'aria-selected:bg-white-400 aria-selected:text-accent-foreground',
              day_hidden: 'invisible',
            }}
            components={{
              IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
              IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { forwardRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { format } from 'date-fns';
import { ReactComponent as MenuIcon } from 'assets/images/common/menu.svg';
import { ReactComponent as OptionIcon } from 'assets/images/common/option.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete.svg';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';
import ActionButton from 'components/buttons/action/ActionButton';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import { useDeleteEventMutation } from 'services/event.service';
import SideBarButton from 'components/sidebar/sidebarButton';
import { getSearchParams } from 'modules/home/utils/helpers';
import { IEvent } from 'types';

type LayoutProps = {
  children: ReactNode;
  step?: string;
  myEvent?: boolean | null;
  widget?: string;
  deleteBtn?: boolean;
  eventId?: string | null;
  // when footer button is clicked
  onSubmit?: () => void;
  loading?: boolean;
  source?: string | null;
  /* harder action competent on the header right */
  actionOnHeaderRight: ReactNode;
  footerText: string;
  headingText?: string;
  navigateBackUrl?: string;
  navigateForwardUrl?: string;
  event?: IEvent | null;
  pastEvent?: boolean;
};

const LetsMeetLayout = forwardRef<(() => void) | null, LayoutProps>(
  (
    {
      children,
      step,
      myEvent,
      widget,
      deleteBtn,
      eventId,
      source,
      navigateBackUrl,
      pastEvent,
      event,
    },
    ref,
  ) => {
    const navigate = useNavigate();

    // get id param if available
    const { id } = useParams<{ id: string }>();

    // get action from URL
    const location = useLocation();
    const match = location.pathname.match(/\/lets-meet\/(\w+)\/.+/);
    const action = match ? match[1] : null;
    const searchParams = getSearchParams(location.search ?? '');
    const directOriginList = ['activities'];
    const originPage = decodeURIComponent(
      searchParams?.origin
        ? directOriginList.includes(searchParams?.origin)
          ? `${searchParams?.origin}`
          : `home/${searchParams?.origin}`
        : 'home',
    );

    const [visible, setVisible] = useState(false);
    const [deleteEvent] = useDeleteEventMutation();

    const getFormattedNavigateBackFromViewUrl = () => {
      let formattedDate = undefined;
      if (navigateBackUrl) {
        return navigateBackUrl;
      }
      if (event) {
        const eventDate = event?.start ?? new Date();
        formattedDate = format(eventDate, 'yyyy-MM-dd');
      }
      if (formattedDate) {
        return `/${originPage}?date=${formattedDate}${id ? `&ref=${id}` : ''}`;
      } else {
        return `/${originPage}${id ? `?ref=${id}` : ''}`;
      }
    };

    const handleDeleteDrawer = () => {
      setVisible(true);
    };

    const handleConfirmationModal = (value: boolean) => {
      setVisible(value);
    };

    const handleDeleteEvent = async () => {
      // delete event
      if (eventId) {
        await deleteEvent(eventId);
        navigate('/home');
      }

      handleConfirmationModal(false);
    };

    const visitEditEvent = () => {
      if (!pastEvent) {
        navigate(`/lets-meet/edit/${eventId}`);
      }
    };

    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
      setMenuVisible(!menuVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className="flex items-center justify-center text-white bg-main">
        <div
          className={cn(
            'flex flex-col min-h-[100dvh] w-full max-w-md py-5 px-5',
          )}
        >
          <header className="flex flex-row justify-center items-center w-full mt-6">
            <div className="w-1/4">
              <ChevronLeft
                className="cursor-pointer"
                onClick={() => {
                  if (navigateBackUrl) {
                    navigate(navigateBackUrl);
                  } else if (action == 'view') {
                    navigate(getFormattedNavigateBackFromViewUrl());
                  } else if (source === 'share') {
                    navigate(`/`);
                  } else if (source === 'review') {
                    navigate(`/lets-meet/edit/${id}`);
                  } else {
                    navigate(-1);
                  }
                }}
              />
            </div>
            {step === 'create' ? (
              <div className="w-1/2">
                <h1 className="text-xl font-bold text-center uppercase Westmount">
                  Let&apos;s Meet
                </h1>
              </div>
            ) : step === 'edit' ? (
              <div className="w-1/2">
                <h1 className="text-xl font-bold text-center uppercase Westmount">
                  Edit
                </h1>
              </div>
            ) : (
              <div className="w-full"></div>
            )}
            {deleteBtn && (
              <div className="w-1/4 flex justify-end">
                <DeleteIcon onClick={handleDeleteDrawer} />
              </div>
            )}
            {widget === 'menu' ? (
              <div className="w-1/4 flex justify-end">
                <SideBarButton />
              </div>
            ) : widget === 'option' ? (
              <div className="relative w-1/4 flex justify-end" ref={menuRef}>
                {myEvent && (
                  <OptionIcon onClick={toggleMenu} className="cursor-pointer" />
                )}
                {menuVisible && (
                  <div className="absolute right-0 mt-7 w-48 bg-gray-800 shadow-lg rounded-md z-10">
                    <div className="py-1">
                      {step !== 'edit' && (
                        <>
                          <span
                            onClick={visitEditEvent}
                            className={`block text-sm px-4 py-2  ${pastEvent ? 'text-gray-500' : 'text-white'}`}
                          >
                            Edit details
                          </span>
                          <hr className="opacity-20" />
                        </>
                      )}

                      <span
                        onClick={() => {
                          handleConfirmationModal(true);
                          toggleMenu();
                        }}
                        className="block text-sm px-4 py-2 text-white"
                      >
                        Delete event
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="flex w-[250px] justify-end"
                onClick={() => navigate('/home?filter=Draft%20Events')}
              >
                <span className="text-sm text-gray-300">Save as Draft</span>
              </div>
            )}
          </header>
          {children}
          <Drawer
            setVisible={setVisible}
            visible={visible}
            className="flex flex-col w-full p-4"
          >
            <Text
              size="small"
              className="px-4 mt-2 mb-2 font-semibold text-center text-md"
            >
              Are you sure you want to delete?
            </Text>
            <Text size="medium" className="mb-6 font-light text-center text-sm">
              You cannot undo this action after you confirm.
            </Text>
            <div className="flex items-center w-full gap-4 px-2 mt-4 mb-6">
              <ActionButton
                variant="secondary"
                label="Cancel"
                className={'text-sm'}
                onClick={() => handleConfirmationModal(false)}
              />
              <ActionButton
                label="Yes, Delete"
                className={'text-sm'}
                onClick={handleDeleteEvent}
              />
            </div>
          </Drawer>
        </div>
      </div>
    );
  },
);

LetsMeetLayout.displayName = 'LetsMeetLayout';

export default LetsMeetLayout;

import React, { useState, useEffect } from 'react';
import { CheckIcon } from 'lucide-react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { IPollData, TimeSlotAvailability } from 'types/event';

export const isTimePassed = (timeString: string): boolean => {
  const match = timeString.match(/^(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})/);
  if (!match) return false;

  const [_, date, time] = match;
  const dateTime = new Date(`${date}T${time}Z`);
  return dateTime < new Date();
};

// TODO: Check if guest, if so then skip avaiability checks
interface PollTimeSelectorProps {
  pollData: IPollData;
  serverPollData: IPollData;
  userId: string;
  onTimeSelectionChange: (updatedSelectedTimes: string[]) => void;
  eventId: string;
  submittedTimes?: string[];
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  isLocked?: boolean; // Add this prop
}

const PollTimeSelector: React.FC<PollTimeSelectorProps> = ({
  pollData,
  serverPollData,
  userId,
  onTimeSelectionChange,
  eventId,
  submittedTimes,
  containerProps,
  isLocked,
}) => {
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [availabilities, setAvailabilities] = useState<TimeSlotAvailability>(
    {},
  );
  const [loading, setLoading] = useState(true);

  // Check availabilities when component mounts
  useEffect(() => {
    const checkAvailabilities = async () => {
      try {
        if (!pollData?.checkedTimes?.length) return;

        const accessToken = sessionStorage.getItem('accessToken');
        const timeslots = pollData.checkedTimes.map((slot) => slot.time);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/event/availability-check`,
          { timeslots, eventId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        setAvailabilities(response.data);
      } catch (error) {
        console.error('Error checking availabilities:', error);
      } finally {
        setLoading(false);
      }
    };

    if (pollData?.checkedTimes?.length) {
      checkAvailabilities();
    }
  }, []);

  // Initialize selectedTimes based on the current user's votes in pollData
  useEffect(() => {
    if (selectedTimes.includes('Not available on all dates')) {
      setSelectedTimes(['Not available on all dates']);
      return;
    }
    if (submittedTimes?.length) {
      setSelectedTimes(submittedTimes);
      return;
    }

    if (serverPollData?.checkedTimes?.length && userId) {
      const userVotes = serverPollData.checkedTimes
        .filter((timeSlot) =>
          timeSlot.voters.some((voter) => voter.userId === userId),
        )
        .map((timeSlot) => timeSlot.time);

      setSelectedTimes(userVotes);
    }
  }, [serverPollData, userId, submittedTimes]);
  const handleTimeToggle = (time: string) => {
    if (isLocked) return; // Don't allow changes if locked

    let updatedSelectedTimes: string[] = [];

    if (time === 'Not available on all dates') {
      if (selectedTimes.includes('Not available on all dates')) {
        updatedSelectedTimes = [];
      } else {
        setSelectedTimes(['Not available on all dates']);
        updatedSelectedTimes = ['Not available on all dates'];
      }
    } else {
      if (selectedTimes.includes('Not available on all dates')) {
        updatedSelectedTimes = [time];
      } else {
        if (selectedTimes.includes(time)) {
          updatedSelectedTimes = selectedTimes.filter((t) => t !== time);
        } else {
          updatedSelectedTimes = [...selectedTimes, time];
        }
      }
    }

    setSelectedTimes(updatedSelectedTimes);
    onTimeSelectionChange(updatedSelectedTimes);
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM d');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`);
      return format(date, 'h:mm a');
    } catch (error) {
      console.error('Error parsing time:', error);
      return 'Invalid Time';
    }
  };

  const getCheckboxStyle = (isSelected: boolean) => {
    if (isLocked) {
      return isSelected
        ? 'bg-gray-400 border-2 border-gray-400' // Selected but locked
        : 'border-2 border-gray-400'; // Unselected and locked (hollow)
    }
    return isSelected
      ? 'bg-[#ABE49C] border-2 border-[#ABE49C]' // Selected and unlocked
      : 'border-2 border-white'; // Unselected and unlocked
  };
  return (
    <div
      className="flex flex-col items-start gap-2 rounded-xl bg-[#1A1F37] py-4 w-full"
      {...containerProps}
    >
      <div className="flex flex-col gap-1 px-4">
        <h2 className="text-white font-semibold text-sm font-manrope">
          {isLocked ? 'Your selected times' : 'Vote for a time(s)'}
        </h2>
        <p className="text-white/80 text-[13px] font-normal font-manrope">
          {isLocked
            ? 'Click "Change times" to modify'
            : 'Select all that apply'}
        </p>
      </div>

      <div className="flex flex-col gap-2 w-full">
        {serverPollData.checkedTimes.map((timeSlot, index) => {
          const isPassed = isTimePassed(timeSlot.time);
          const matchResult = timeSlot.time.match(
            /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
          );

          const [date, startTime] = matchResult
            ? [matchResult[1], matchResult[2] + (matchResult[3] || '')]
            : ['', ''];

          return (
            <div
              key={index}
              className={`relative px-4 py-3 cursor-pointer transition-all
                ${isLocked ? 'opacity-50' : ''} 
                ${isPassed ? 'opacity-50 cursor-not-allowed' : ''}
                ${!isLocked && !isPassed ? 'hover:bg-white/5' : ''}`}
              onClick={() => !isPassed && handleTimeToggle(timeSlot.time)}
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-3">
                  <div className="flex-shrink-0 h-[34px] flex items-center">
                    <div
                      className={`flex justify-center items-center w-[18px] h-[18px] rounded-[2px] transition-colors
                        ${getCheckboxStyle(selectedTimes.includes(timeSlot.time))}`}
                    >
                      {selectedTimes.includes(timeSlot.time) && (
                        <CheckIcon className="w-4 h-4 text-[#110E2B]" />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-white font-manrope text-sm">
                      {`${formatDate(date)} at ${formatTime(date, startTime)}`}
                    </span>
                    <span className="text-white/60 font-manrope text-sm tracking-wide">
                      {serverPollData.checkedTimes.find(
                        (t) => t.time === timeSlot.time,
                      )?.voters?.length || 0}{' '}
                      Voted
                    </span>
                  </div>
                </div>
                {!loading && availabilities[timeSlot.time] && (
                  <span
                    className={`font-manrope text-sm font-medium leading-6 tracking-wide ${
                      availabilities[timeSlot.time].availability
                        ? 'text-[#ABE49C]'
                        : 'text-[#FF6A62]'
                    }`}
                  >
                    {availabilities[timeSlot.time].availability
                      ? 'Available'
                      : ''}
                  </span>
                )}
              </div>
            </div>
          );
        })}

        <div
          className={`px-4 py-3 cursor-pointer ${isLocked ? 'opacity-50' : ''}`}
          onClick={() =>
            !isLocked && handleTimeToggle('Not available on all dates')
          }
        >
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0 h-[34px] flex items-center">
              <div
                className={`flex justify-center items-center w-[18px] h-[18px] rounded-[2px]
                  ${getCheckboxStyle(selectedTimes.includes('Not available on all dates'))}`}
              >
                {selectedTimes.includes('Not available on all dates') && (
                  <CheckIcon className="w-4 h-4 text-[#110E2B]" />
                )}
              </div>
            </div>
            <span className="text-white font-manrope text-sm">
              None of these work
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollTimeSelector;

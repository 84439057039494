import React, {
  Fragment,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'date-fns';
import { ReactComponent as ClockIcon } from 'assets/images/activity/clock.svg';
import { Text } from 'components/text/primary/Text';
import { Separator } from 'components/ui/separator';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { getSearchParams } from 'modules/home/utils/helpers';
import AvailabilityEmptyList from './emptyList';

type UserActivityProps = {
  activities: any;
};

const UserActivities: React.FC<UserActivityProps> = ({ activities }) => {
  const navigate = useNavigate();

  // get search params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const focusToEventId = searchParams?.ref;
  const focusEventDate = searchParams?.date;
  const formattedFocusEventDate = focusEventDate
    ? parse(focusEventDate, 'yyyy-MM-dd', new Date())
    : null;

  // initial data loaded flag
  const initialDataLoadedRef = useRef(false);

  // initial data load handler
  const handleInitialDataLoad = () => {
    focusToEventId &&
      setTimeout(() => {
        const element = document.getElementById(focusToEventId);
        element &&
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 50);
  };

  // initial data load effect
  useEffect(() => {
    if (activities && !initialDataLoadedRef.current) {
      handleInitialDataLoad();
      initialDataLoadedRef.current = true;
    }
  }, [activities]);

  return (
    <div className="mt-3">
      <Text size="medium" className="py-1">
        Friend Activity
      </Text>

      <div className="my-2.5 flex gap-2.5 flex-col">
        {activities?.length === 0 ? (
          <AvailabilityEmptyList
            icon={<ClockIcon />}
            text="You have no activities to show here"
          />
        ) : (
          activities?.map(
            (
              activity: {
                _id: Key | null | undefined;
                icon: string;
                title: string | null | undefined;
                body:
                  | string
                  | number
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | ReactPortal
                  | null
                  | undefined;
                eventId: string;
              },
              index: number,
            ) => (
              <Fragment key={activity._id}>
                <div
                  className="flex gap-2.5 items-center"
                  onClick={() =>
                    navigate(
                      `/lets-meet/receive/${activity?.eventId}?origin=activities`,
                    )
                  }
                  id={activity.eventId}
                >
                  <div className="flex shrink-0">
                    <ProfileAvatar size={45} url={activity?.icon} hideBorder />
                    {/* <EventIcon icon={activity.icon} size="medium"/>*/}
                  </div>
                  <div>
                    {/* TODO: update this  */}
                    <div className="text-sm font-bold">
                      {activity?.title?.split('/')[0]}
                    </div>
                    <div className="text-xs">{activity.body}</div>
                  </div>
                </div>

                {index !== activities.length - 1 && (
                  <Separator className="bg-white/20" />
                )}
              </Fragment>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default UserActivities;

import { TokenResponse } from 'modules/auth/types/types';

const getToken = (): string | null => {
  return sessionStorage.getItem('accessToken');
};

const setToken = (token: string): void => {
  sessionStorage.setItem('accessToken', token);
};

const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

const setRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

const clearTokens = (): void => {
  sessionStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const setTokens = (tokenResponse: TokenResponse): void => {
  setToken(tokenResponse?.access?.token);
  setRefreshToken(tokenResponse?.refresh?.token);
};

const tokenUtils = {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  clearTokens,
  setTokens,
};

export default tokenUtils;

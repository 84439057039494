import axios from 'axios';

interface PushInit {
  registration: ServiceWorkerRegistration;
  subscription: PushSubscription;
}

export const webPushNotifications = {
  initialized: false,

  urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },

  async init(): Promise<PushInit> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      throw new Error('Push notifications are not supported');
    }

    try {
      const publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
      if (!publicVapidKey) {
        throw new Error('VAPID public key not found');
      }

      // Check existing registrations and clean up
      const existingRegistrations =
        await navigator.serviceWorker.getRegistrations();
      for (const registration of existingRegistrations) {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          await subscription.unsubscribe();
        }
        await registration.unregister();
      }

      // Create new registration and subscription
      const registration =
        await navigator.serviceWorker.register('/push-sw.js');
      await navigator.serviceWorker.ready;

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey),
      });

      this.initialized = true;
      return { registration, subscription };
    } catch (error) {
      console.error('Service Worker registration failed:', error);
      throw error;
    }
  },

  async testSubscription(subscription: PushSubscription) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/test-subscription`,
        { subscription: subscription.toJSON() },
        { headers },
      );
      return true;
    } catch (error: any) {
      if (error.response?.status === 410) {
        return false; // Subscription is expired
      }
      throw error;
    }
  },

  async checkSubscription(): Promise<boolean> {
    try {
      console.log('Starting subscription check...');

      // Check service worker and subscription
      console.log('Waiting for service worker to be ready...');
      const registration = await navigator.serviceWorker.ready;
      console.log('Service worker ready, getting subscription...');
      const subscription = await registration.pushManager.getSubscription();
      console.log('Current subscription:', subscription ? 'exists' : 'none');

      if (!subscription) {
        console.log('No local subscription found');
        return false;
      }

      // Backend verification
      console.log('Verifying with backend...');
      const accessToken = sessionStorage.getItem('accessToken');
      if (!accessToken) {
        console.log('No access token found');
        return false;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      try {
        console.log('Making backend check request...');
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/notify/check-subscription`,
          {
            subscription: subscription.toJSON(),
            deviceInfo: {
              userAgent: window.navigator.userAgent,
              deviceType: /mobile|android|iphone|ipad/i.test(
                window.navigator.userAgent,
              )
                ? 'mobile'
                : 'desktop',
              lastUsed: new Date(),
            },
          },
          { headers },
        );
        console.log('Backend response:', response.data);

        if (!response.data.isValid) {
          console.log('Backend reports subscription invalid, cleaning up...');
          await subscription.unsubscribe();
          return false;
        }

        console.log('Testing subscription functionality...');
        const isValid = await this.testSubscription(subscription);
        console.log('Subscription test result:', isValid);
        return isValid;
      } catch (error) {
        console.error('Backend check failed:', error);
        return false;
      }
    } catch (error) {
      console.error('Subscription check failed:', error);
      return false;
    }
  },

  async requestPermission(userId: string) {
    try {
      console.log('Starting permission request process...');

      // Step 1: Request notification permission.
      if (Notification.permission !== 'granted') {
        console.log('Requesting notification permission...');
        const permission = await Notification.requestPermission();
        console.log('Permission response:', permission);
        if (permission !== 'granted') {
          throw new Error('Permission not granted for notifications');
        }
      } else {
        console.log('Notification permission already granted.');
      }

      // Step 2: Initialize the service worker and subscribe.
      console.log('Initializing service worker and push subscription...');
      const { registration, subscription } = await this.init();

      if (!subscription) {
        console.log('Failed to get subscription after initialization');
        throw new Error('Failed to get subscription after initialization');
      }

      // Step 3: (Optional) Verify subscription with your backend if needed
      // For example, you could call your checkSubscription() here if you want to reuse an existing one.
      // However, given that your init() function cleans up any previous registrations,
      // you're effectively always creating a new subscription.

      // Step 4: Register the subscription with the backend.
      console.log('Registering subscription with backend...');
      const accessToken = sessionStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/register`,
        {
          subscription: subscription.toJSON(),
          deviceInfo: {
            userAgent: window.navigator.userAgent,
            deviceType: /mobile|android|iphone|ipad/i.test(
              window.navigator.userAgent,
            )
              ? 'mobile'
              : 'desktop',
            lastUsed: new Date(),
          },
        },
        { headers },
      );

      console.log('Successfully completed permission request process');
      return subscription;
    } catch (error) {
      console.error('Error in requestPermission:', error);
      throw error;
    }
  },

  async unsubscribe() {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();

      for (const registration of registrations) {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          const endpoint = subscription.endpoint;
          await subscription.unsubscribe();

          const accessToken = sessionStorage.getItem('accessToken');
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };

          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/notify/unregister`,
            { endpoint },
            { headers },
          );
        }
        await registration.unregister();
      }

      this.initialized = false;
    } catch (error) {
      console.error('Error unsubscribing from push notifications:', error);
      throw error;
    }
  },

  async sendTestNotification(userId: string) {
    // Add userId parameter
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/test`,
        { userId }, // Add userId to request body
        { headers },
      );
    } catch (error) {
      console.error('Error sending test notification:', error);
      throw error;
    }
  },
};

import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'components/ui/sheet';

import { ReactComponent as LogoIcon } from 'assets/images/sidebar/logo.svg';
import { Separator } from 'components/ui/separator';

// Icons
import { ReactComponent as ActivityIcon } from 'assets/images/sidebar/activity.svg';
import { ReactComponent as AvailabilityIcon } from 'assets/images/sidebar/availability.svg';
import { ReactComponent as ContactsIcon } from 'assets/images/sidebar/contacts.svg';
import { ReactComponent as DraftsIcon } from 'assets/images/sidebar/draft.svg';
import { ReactComponent as MeetIcon } from 'assets/images/sidebar/meet.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/sidebar/settings.svg';
import { logoutAction } from 'modules/auth/slices/actions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import tokenService from 'modules/auth/utils/tokenUtils';

type SideBarProps = {
  open: boolean;
  onClose: () => void;
};

const BIG_CALENDER_LINKS = {
  Summary: '',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
};

const NAV_LINKS = [
  { name: 'Let’s meet', icon: MeetIcon, link: '/lets-meet' },
  { name: 'Activity', icon: ActivityIcon, link: '/activities' },
  { name: 'Contacts', icon: ContactsIcon, link: '/settings/edit/contacts' },
  {
    name: 'Edit availability',
    icon: AvailabilityIcon,
    link: '/settings/edit/availability',
  },
  { name: 'Drafts', icon: DraftsIcon, link: '/home?filter=Draft%20Events' },
  { name: 'Settings', icon: SettingsIcon, link: '/settings' },
];

const SideBar: React.FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loggingOut, setLoggingOut] = useState(false);

  const logout = async () => {
    if (loggingOut) return;
    setLoggingOut(true);

    await dispatch(logoutAction());

    setLoggingOut(false);
    tokenService.clearTokens();
    navigate('/auth/start');
  };

  return (
    <Sheet onOpenChange={props.onClose} open={props.open}>
      <SheetContent
        className="bg-main border-none rounded-l-xl pt-16 pb-14 flex flex-col overflow-auto"
        hideClose
      >
        <SheetHeader>
          <div className="invisible hidden">
            <SheetTitle className="text-white font-bold text-lg">
              Menu
            </SheetTitle>
            <SheetDescription className="text-white text-sm">
              Welcome to your Caleido
            </SheetDescription>
          </div>
          <LogoIcon />
        </SheetHeader>

        <Separator className="bg-white/20 h-[.5px]" />

        {/* <div className="flex flex-col gap-5 px-3">
          {Object.entries(BIG_CALENDER_LINKS).map(([key, value]) => (
            <Link
              className="block text-secondary font-medium text-sm"
              to={`/home/${value}`}
              key={key}
              onClick={props.onClose}
            >
              {key}
            </Link>
          ))}
        </div>

        <Separator className="bg-white/20 h-[.5px] " /> */}

        <div className="flex flex-col gap-2">
          {NAV_LINKS.map((nav) => (
            <Link
              className="flex items-center gap-4 text-secondary font-medium text-sm px-3 py-2  w-fit"
              to={nav.link}
              key={nav.name}
            >
              <nav.icon />
              {nav.name}
            </Link>
          ))}
        </div>

        <SheetFooter className="flex-col mt-auto items-center">
          <Separator className="bg-white/20 h-[.5px]" />
          <Button
            variant="link"
            className="text-secondary font-semibold mt-9 w-fit focus:border-none focus:outline-none"
            autoFocus={false}
            onClick={logout}
          >
            Logout
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SideBar;

import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useLocation, useMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { Text } from 'components/text/primary/Text';
import AvailabilityInput from 'modules/profile/components/availability/AvailabilitySlot/AvailabilitySlot';
import {
  IAvailability,
  IAvailabilityPeriod,
} from 'modules/profile/types/types';
import { AvailabilityPeriodModel } from 'modules/profile/model/Period';
import SyncCalenderButton from 'modules/profile/components/sync/SyncCalendarButton';
import { ReactComponent as CalendarIcon } from 'assets/images/profile/calender-icon.svg';
import { daysOfWeek } from 'constants/common';
import WeekdaySelector from 'components/calender/WeekdaySelector';
import { IUser } from 'modules/auth/types/types';
import { Checkbox } from 'components/ui/checkbox';
import {
  getGeneralTimeFormat,
  isValidTimeSlot,
  normalizeSlots,
  validateAvailabilityPeriods,
} from 'modules/profile/utils/availability';
import { toast } from 'components/ui/toast/use-toast';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUser } from 'modules/auth/slices/authSlice';
import RecurringAvailabilitySlot from './AvailabilitySlot/RecurringAvailabilitySlot';

type AvailabilityProps = {
  setPrevOrSkip: () => void;
  update?: any;
  data?: IUser | undefined;
  title?: string;
};

const daysOrder = Object.keys(daysOfWeek);

const Availability = forwardRef<(() => void) | null, AvailabilityProps>(
  ({ setPrevOrSkip, update, data, title }, ref) => {
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [recurringSelectedDays, setRecurringSelectedDays] = useState<
      string[]
    >([]);
    const [availability, setAvailability] = useState<IAvailability | null>({});
    const [enableRecurring, setEnableRecurring] = useState<boolean>(false);
    const [recurringTimes, setRecurringTimes] = useState<IAvailabilityPeriod[]>(
      [],
    );

    const location = useLocation();

    const dispatch = useAppDispatch();

    const handleSubmit = () => {
      onSubmitForm(
        enableRecurring,
        availability,
        recurringTimes,
        recurringSelectedDays,
      );
    };

    useImperativeHandle(ref, () => handleSubmit);

    const validate = () => {
      if (enableRecurring) {
        if (recurringSelectedDays.length === 0 || recurringTimes.length === 0) {
          return 'Please select the days you are available.';
        }

        for (const period of recurringTimes) {
          if (!period.end)
            return 'Please select correct time slots you are available.';

          if (!isValidTimeSlot(period.start, period.end)) {
            return 'Please select correct time slots you are available.';
          }
        }

        if (validateAvailabilityPeriods(recurringTimes)) {
          return 'Some time slots overlap. Please select correct time slots you are available.';
        }
        return true;
      } else {
        if (!availability || selectedDays.length === 0) {
          return 'Please select the days and time slots you are available.';
        }

        for (const day of Object.keys(availability)) {
          const periods = availability[day as keyof IAvailability];

          if (isEmpty(periods)) {
            return 'Please select correct time slots you are available.';
          }

          if (periods![0].allDay) continue;

          for (const period of periods!) {
            if (!period.end)
              return 'Please select correct time slots you are available.';
            if (!isValidTimeSlot(period.start, period.end)) {
              return 'Please select correct time slots you are available.';
            }
          }

          if (validateAvailabilityPeriods(periods!)) {
            return 'Some time slots overlap. Please select correct time slots you are available.';
          }
        }
        return true;
      }
    };

    const onSubmitForm = async (
      isRecurring: boolean,
      currentAvailability: IAvailability | null,
      currentRecurring: IAvailabilityPeriod[],
      days: string[],
    ) => {
      const validation = validate();

      if (validation !== true) {
        return toast({
          title: validation,
        });
      }
      try {
        const reqData: { [key: string]: any } = {
          username: data?.username,
          availabilityTimezone: new Intl.DateTimeFormat().resolvedOptions()
            .timeZone,
        };

        if (isRecurring) {
          const slots = normalizeSlots(cloneDeep(currentRecurring));
          setRecurringTimes(slots);

          reqData.isRecurring = true;
          reqData.recurring = slots;
          reqData.recurringDates = days;

          // replicate the slots for each recurring day
          const formattedAvailability: any = {};
          days.forEach((day) => {
            formattedAvailability[day] = slots;
          });
          reqData.availability = formattedAvailability;
        } else {
          if (availability === null)
            return toast({
              title: 'Please select the days and time slots you are available.',
            });

          reqData.isRecurring = false;
          reqData.recurring = [];
          reqData.recurringDates = [];
          const normalizedAvailability: IAvailability = {};

          // normalize the slots
          Object.keys(currentAvailability as IAvailability).forEach((day) => {
            if (!availability?.[day as keyof IAvailability]) return;

            if (availability?.[day as keyof IAvailability]?.length === 0)
              return;

            if (availability?.[day as keyof IAvailability]?.[0]?.allDay) {
              normalizedAvailability[day as keyof IAvailability] =
                currentAvailability![day as keyof IAvailability];
              return;
            } else
              normalizedAvailability[day as keyof IAvailability] =
                normalizeSlots(
                  cloneDeep(
                    availability?.[
                      day as keyof IAvailability
                    ] as IAvailabilityPeriod[],
                  ),
                );
          });

          // send the normalized availability
          reqData.availability = normalizedAvailability;

          /*
          reqData.availability = currentAvailability;
          */
        }

        const response = await update?.({ data: reqData }).unwrap();

        if (response) {
          dispatch(setUser(response));
          setPrevOrSkip?.();
        }
      } catch (error) {
        toast({
          title: "Couldn't update availability. Please try again",
        });
        console.log(error);
      }
    };

    const handleDaysChange = (newSelectedDays: string[]) => {
      if (enableRecurring) {
        return setRecurringSelectedDays(newSelectedDays);
      }
      const newAvailability: IAvailability = {};
      newSelectedDays.forEach((day) => {
        newAvailability[day as keyof IAvailability] = isEmpty(
          availability?.[day as keyof IAvailability],
        )
          ? [new AvailabilityPeriodModel()]
          : availability?.[day as keyof IAvailability]!;
      });
      setSelectedDays(newSelectedDays);
      setAvailability(newAvailability);
    };

    const handleTickRecurringTimes = (
      checked: boolean,
      availability: IAvailability | null | undefined,
    ) => {
      setEnableRecurring(checked);

      if (checked && recurringTimes.length === 0) {
        const newRecurringTimes: IAvailabilityPeriod[] = [];
        selectedDays.forEach((day) => {
          const periods = availability?.[day as keyof IAvailability];
          if (periods) {
            periods.forEach((period) => {
              if (period.allDay) return;
              newRecurringTimes.push(period);
            });
          }
        });
        setRecurringTimes(newRecurringTimes);
      }
      // sync dates
      if (checked) setRecurringSelectedDays(selectedDays);
    };

    const sortedAvailabilityDays = Object.keys(availability ?? {}).sort(
      (a, b) => daysOrder.indexOf(a) - daysOrder.indexOf(b),
    );
    const EmptySlots = (
      <div className="flex flex-col items-center justify-center gap-4 px-12 py-24 text-center">
        <CalendarIcon />
        <Text size="small" className="text-slate-400">
          Start by selecting the days of the week that you are free
        </Text>
      </div>
    );

    const RenderAvailabilityInputs = () =>
      !isEmpty(sortedAvailabilityDays) ? (
        <>
          {sortedAvailabilityDays?.map((day, index, array) => (
            <Fragment key={day}>
              <AvailabilityInput
                title={day as keyof IAvailability}
                availability={availability!}
                periods={availability?.[day as keyof IAvailability] || []}
                onChange={setAvailability}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
              />
              {index < array.length - 1 && (
                <div className="w-full h-[1px] mb-3 border-b border-slate-300 border-opacity-20 bg-opacity-5 rounded-full"></div>
              )}
            </Fragment>
          ))}
        </>
      ) : (
        EmptySlots
      );

    const RenderRecurringInputs = () =>
      recurringSelectedDays.length == 0 ? (
        EmptySlots
      ) : (
        <RecurringAvailabilitySlot
          slots={recurringTimes}
          onChange={setRecurringTimes}
        />
      );

    useEffect(() => {
      setEnableRecurring(data?.isRecurring ?? false);
      setRecurringTimes((data?.recurring as IAvailabilityPeriod[]) ?? []);
      setRecurringSelectedDays(data?.recurringDates ?? []);

      // prevent invalid conversions
      if (!data) return;

      const _availability = data?.availability as IAvailability;
      const newAvailability: IAvailability = {};
      if (_availability) {
        Object.keys(_availability).forEach((day) => {
          const periods = _availability[day as keyof IAvailability];
          if (periods) {
            newAvailability[day as keyof IAvailability] = periods.map(
              (period) => {
                return new AvailabilityPeriodModel(
                  periods,
                  period.allDay,
                  period.id,
                  // format the date time
                  // TODO: implement correct format when sending all day availability
                  getGeneralTimeFormat(period.start.dateTime),
                  getGeneralTimeFormat((period.end as any).dateTime),
                  /*
                  period.start.dateTime,
                  (period.end as any).dateTime,
                  */
                );
              },
            );
          }
        });

        setAvailability(newAvailability);
      }

      const availabilityKeys = data?.availability
        ? Object.keys(data?.availability as {})
        : undefined;

      if (availabilityKeys) {
        if (availabilityKeys.length === 0) {
          return;
        }
        setSelectedDays(availabilityKeys);
      }
    }, [
      data?.availability,
      data?.isRecurring,
      data?.recurring,
      data?.recurringDates,
    ]);

    return (
      <div className="flex flex-col items-center w-full gap-2 mb-12">
        <div className="flex flex-col w-full gap-1">
          <Text size="large">{title ?? 'Setup your social availability'}</Text>
          <Text size="small" className="text-sm font-sofia-extralight">
            What days and times are you typically free for social time?
            (repeated weekly)
          </Text>
        </div>

        {location.pathname.startsWith('/settings') && (
          <SyncCalenderButton setPrevOrSkip={() => {}} />
        )}

        <WeekdaySelector
          value={enableRecurring ? recurringSelectedDays : selectedDays}
          onChange={handleDaysChange}
        />
        <div className="flex items-center justify-start w-full gap-2 mb-2">
          <Checkbox
            checked={enableRecurring}
            className="bg-purple-300 data-[state=checked]:bg-purple-300  "
            onCheckedChange={(checked: boolean) => {
              handleTickRecurringTimes(checked, availability);
              setEnableRecurring(checked);
            }}
          />
          <Text size="small" className="font-sofia-extralight text-sm">
            Set the same time slot for all selected days
          </Text>
        </div>

        {enableRecurring ? (
          <RenderRecurringInputs />
        ) : (
          <RenderAvailabilityInputs />
        )}
      </div>
    );
  },
);

Availability.displayName = 'Availability';

export default Availability;

import { Key, useEffect, useRef } from 'react';
import { EventCardItem } from 'components/eventCardItem';
import { EventItem } from 'components/eventCardItem/EventCardItem.types';
import { findEventOfDate } from 'utils/calendarUtils';
import { useApiCall } from 'hooks/axiosHooks';
import InsideLoader from 'components/loader/InsideLoader';
import { MonthPill } from 'components/eventPagination/monthPill';
import { DraftEventPaginationProps } from './DraftEventPagination.types';

const DraftEventPagination = ({ selectedDates }: DraftEventPaginationProps) => {
  const {
    data: events,
    refetch: refetchEvent,
    isFetching,
    isLoading,
  } = useApiCall();

  const loadEvents = () => {
    refetchEvent(`event/status/0`);
  };

  useEffect(() => {
    loadEvents();
  }, []);

  // category pills
  const categoryPillsRef = useRef<string[]>([]);

  useEffect(() => {
    // clear the category pills on every render
    categoryPillsRef.current = [];
  });

  // scroll to the selected date related events
  useEffect(() => {
    if (selectedDates && selectedDates.length > 0 && events) {
      const found = findEventOfDate(
        events.map((e: EventItem) => {
          return {
            event: e,
            variantData: { isDraft: true },
          };
        }),
        selectedDates[0],
      );
      if (found) {
        const element = document.getElementById(found.event?._id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [selectedDates, events]);

  return (
    <div>
      {isFetching && <InsideLoader />}
      {events && !events?.length && (
        <div className="text-center text-white">
          <p>No events found</p>
        </div>
      )}
      <div className="flex gap-2 flex-col">
        {events?.map((event: EventItem, index: Key | null | undefined) => (
          // <>
          // {/* Use for month pill */}
          // {/* <MonthPill pillRef={categoryPillsRef} event={event} /> */}
          <div key={index} className="mb-2" id={event?._id}>
            <EventCardItem
              id={event?._id}
              key={index}
              eventData={event}
              variant={'draft'}
            />
          </div>
          // </>
        ))}
      </div>
    </div>
  );
};

export default DraftEventPagination;

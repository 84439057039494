import { createApi } from '@reduxjs/toolkit/query/react';
import { IUser } from 'modules/auth/types/types';
import baseQueryWithReauth from 'services/mutationInterceptor';
import { IActivity } from 'types/activity';

interface CreateActivityRequest {
  title: string;
  body: string;
  type: string;
  icon?: string;
  eventId?: string;
  userId?: string;
  user?: IUser;
}
interface UpdateActivityRequest {
  eventId: string;
  type: string;
}

// TODO: need to enforce types on the response. We should avoid "any" as much as possible!
export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Get', 'Activity'],
  endpoints: (builder) => ({
    createActivity: builder.mutation<any, CreateActivityRequest>({
      query: (data) => ({
        url: '/activity',
        method: 'POST',
        body: data,
      }),
    }),
    createActivityPublic: builder.mutation<any, CreateActivityRequest>({
      query: (data) => ({
        url: '/activity/public',
        method: 'POST',
        body: data,
      }),
    }),
    getActivities: builder.query<any, string>({
      query: (type) => ({
        url: `/activity/${type}`,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
        },
      }),
      transformResponse: (response) => {
        console.log('Event response:', response);
        return response;
      },
    }),
    updateActivity: builder.mutation<any, UpdateActivityRequest>({
      query: (data) => ({
        url: '/activity/update',
        method: 'PUT',
        body: data,
      }),
    }),
    getAllEventActivities: builder.query<IActivity[], string>({
      query: (eventId) => ({
        url: `/activity/event/${eventId}`,
        method: 'GET',
      }),
      providesTags: ['Activity'],
    }),
    getInviteActivity: builder.query<IActivity, string>({
      query: (eventId) => ({
        url: `/activity/event/${eventId}/invite`,
        method: 'GET',
      }),
    }),
    getAllEventActivitiesPublic: builder.query<IActivity[], string>({
      query: (eventId) => ({
        url: `/activity/event/public/${eventId}`,
        method: 'GET',
      }),
      providesTags: ['Activity'],
    }),
    getInviteActivityPublic: builder.query<IActivity, string>({
      query: (eventId) => ({
        url: `/activity/event/public/${eventId}/invite`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateActivityMutation,
  useCreateActivityPublicMutation,
  useGetActivitiesQuery,
  useUpdateActivityMutation,
  useGetAllEventActivitiesQuery,
  useGetInviteActivityQuery,
  useGetAllEventActivitiesPublicQuery,
  useGetInviteActivityPublicQuery,
} = activityApi;

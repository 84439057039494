import { ChevronDown } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { format, parseISO } from 'date-fns';
import { cn } from 'utils/helpers';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import { ReactComponent as ClockIcon } from 'assets/images/profile/clock-icon.svg';
import {
  convertCommonTo12HoursFormat,
  convertCommonTo24HoursFormat,
} from 'utils/calendarUtils';
import useIsMobileDevice from 'hooks/isMobileDevice';
import useIsMobileOS from 'hooks/isMobileOS';
import useIsMobileIOS from 'hooks/isIOS';

dayjs.extend(utc);
dayjs.extend(timezone);

type TimeDropdownProps = {
  selectedTime?: string;
  onTimeSelect: (time: ITimeSlot) => void;
  className?: string;
  error?: boolean;
  startTime?: string;
  disabled?: boolean;
  useDefaultTime?: boolean;
  showSelectedInitially?: boolean;
};

const NativeTimeSelector: React.FC<TimeDropdownProps> = (props) => {
  const isMobileDevice = useIsMobileDevice();
  const isMobileOS = useIsMobileOS();
  const isMobileIOS = useIsMobileIOS();

  const [selectedValue, setSelectedValue] = useState<any>();

  const inputRef = useRef<HTMLInputElement>(null);

  const convertTo24HourFormat = (newTime: string) => {
    const [time, period] = newTime.split(' ');
    const [hours12, minutes] = time.split(':').map(Number);
    const hours24 =
      period === 'PM' && hours12 !== 12
        ? hours12 + 12
        : period === 'AM' && hours12 === 12
          ? 0
          : hours12;
    return `${hours24.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const selectedValueIn12Hours = selectedValue
    ? convertCommonTo12HoursFormat(selectedValue.dateTime)
    : props.selectedTime
      ? props.selectedTime
      : undefined;

  const getTime = (time: string, format?: string) => {
    const today = dayjs().format('YYYY-MM-DD');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return [dayjs.tz(`${today}T${time}`, timeZone).format(format), timeZone];
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userSelectedTime = e.target.value;
    const [dateTime, timeZone] = getTime(userSelectedTime);
    setSelectedValue({ dateTime, timeZone });
  };

  // hide selector and set value on props on blur for non-mobile devices
  const handleTimeSelectorClosed = () => {
    selectedValue && props.onTimeSelect(selectedValue);
  };

  function convertTo24TimeFormat(datetime: string) {
    if (!datetime) return undefined;
    if (datetime.includes('AM') || datetime.includes('PM'))
      return dayjs(datetime).format('HH:mm');
    return format(parseISO(datetime), 'HH:mm');
  }

  const convertTo12HourFormat = (newTime: string) => {
    if (!newTime) return undefined;
    if (newTime.includes('AM') || newTime.includes('PM')) return newTime;
    const [hours, minutes] = newTime.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  };
  return (
    <div
      className={cn(
        'relative w-full max-w-xs mx-auto font-sans text-sm font-medium text-gray-400 border rounded-full bg-[#32395E] border-transparent overflow-hidden',
        props.className,
        props.error && 'border-red-500',
        props.disabled && 'opacity-50',
      )}
    >
      <div
        className="flex items-center justify-center relative w-full"
        onClick={() => {
          // using the same logic as in the original code to open the time picker
          if (inputRef.current && !props.disabled) {
            try {
              inputRef.current.click();
              inputRef.current.focus();
              inputRef.current.showPicker();
            } catch (error) {
              console.error(error);
            }
          }
        }}
      >
        <div
          className={`relative ${isMobileIOS ? 'px-6 py-2.5' : 'px-4'} w-full min-w-11 `}
        >
          <span
            className={`absolute left-8 px-2 bottom-[50%] translate-y-[50%] bg-[#32395E] ${selectedValue || props.selectedTime ? 'text-white' : 'text-gray-500'} pointer-events-none min-w-16  py-1 text-nowrap whitespace-nowrap break-keep`}
          >
            {selectedValueIn12Hours ?? 'HH:MM'}
          </span>
          <span className="absolute left-3 bottom-[50%] translate-y-[50%]">
            <ClockIcon />
          </span>
          <input
            placeholder="HH:MM"
            ref={inputRef}
            type="time"
            className="z-10 gap-1 py-2.5 pl-4 pr-2 text-left rounded-s-lg focus:outline-none app bg-[#32395E] text-[#32395E] w-full select-none native-time-selector"
            onChange={handleChange}
            // 15 mins interval
            step={900}
            value={
              selectedValueIn12Hours
                ? convertCommonTo24HoursFormat(selectedValueIn12Hours)
                : undefined
            }
            min={
              props.startTime
                ? convertCommonTo24HoursFormat(props.startTime)
                : undefined
            }
            onBlur={handleTimeSelectorClosed}
          />
          <span
            className={`absolute right-4 bottom-[50%] translate-y-[50%] h-2 w-2 ${isMobileOS ? 'hidden' : ''}`}
          >
            <ChevronDown size={12} color="#EEEBF3" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NativeTimeSelector;

import { FC, useRef, useState } from 'react';
import { find } from 'lodash';
import { ChevronDown } from 'lucide-react';
import { cn } from 'utils/helpers';

type CountryCodeDropdownProps = {
  countryCodes: { code: string; name: string; prefix: string }[];
  selectedCode: string;
  phoneNumber: string;
  onCodeSelect: (code: string) => void;
  onPhoneNumberChange: (number: string) => void;
  className?: string;
  placeHolder?: string;
  inputClassName?: string;
  selectorClassName?: string;
  triggerClassName?: string;
};

const CountryCodeDropdown: FC<CountryCodeDropdownProps> = ({
  countryCodes,
  selectedCode,
  phoneNumber,
  onCodeSelect,
  onPhoneNumberChange,
  className,
  placeHolder,
  inputClassName,
  selectorClassName,
  triggerClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedCountry = find(countryCodes, { code: selectedCode });
  const prefix = selectedCountry?.prefix ?? 'US';

  const handleSelect = (code: string) => {
    onCodeSelect(code);
    setIsOpen(false);
  };

  const handleBlur = (event: React.FocusEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.relatedTarget as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    // Filter out non-numeric characters
    const formattedPhoneNumber = input.replace(/\D/g, '');
    onPhoneNumberChange(formattedPhoneNumber);
  };

  return (
    <div
      className={cn(
        'relative w-full max-w-sm mx-auto font-sans text-sm font-medium text-[#EBDFFF] bg-[#32395E] rounded-full',
        className,
      )}
      onBlur={handleBlur}
      tabIndex={-1}
      ref={dropdownRef}
    >
      <div className="flex items-center justify-center">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`flex-shrink-0 z-10 inline-flex items-center justify-start gap-1 py-2.5 pl-4 pr-2 text-center rounded-s-lg focus:outline-none ${triggerClassName ?? ''}`}
          type="button"
        >
          {prefix}
          <ChevronDown size={16} color="#EEEBF3" />
        </button>
        {isOpen && (
          <div
            className={`absolute left-0 z-10 w-full p-2 overflow-hidden text-white bg-white border rounded-lg bg-opacity-10 border-slate-600 top-12 backdrop-blur-lg backdrop-filter ${selectorClassName ?? ''}`}
          >
            <div className="overflow-y-auto max-h-32 scrollbar-none">
              <ul className="py-2">
                {countryCodes?.map(({ code, name, prefix }) => (
                  <li key={code}>
                    <button
                      type="button"
                      className={cn(
                        'inline-flex items-center justify-between w-full px-4 py-2 mb-1 rounded-lg hover:bg-slate-50 hover:bg-opacity-20',
                        code === selectedCode && 'bg-slate-100 bg-opacity-20',
                      )}
                      onClick={() => handleSelect(code)}
                    >
                      <p className="text-left truncate">{name}</p>{' '}
                      <p>({code})</p>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="relative w-full">
          <input
            type="text"
            id="phone-input"
            inputMode="tel"
            autoComplete="mobile tel-local-prefix webauthn"
            className={`block p-2.5 w-full z-20 rounded-r-lg outline-none bg-transparent border-0 ${inputClassName ?? ''}`}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={placeHolder ?? '000 000 0000'}
            maxLength={10}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default CountryCodeDropdown;

import { ChevronDown } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

type CustomSelectProps = {
  options: { label: string; value: string }[];
  selectedValue: string;
  onValueChange: (value: string) => void;
  direction?: 'up' | 'down';
  placeHolder?: string;
};

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { options, selectedValue, onValueChange, direction = 'down' } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  // Handle click outside to close the menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={'relative'}>
      <div
        onClick={toggleMenu}
        className={
          'text-sm bg-[#32395E]  rounded-full w-full px-3 py-2 font-sofia-light'
        }
        ref={dropdownRef}
      >
        <div className={'w-full flex justify-between items-center'}>
          {options.find((item) => item.value === selectedValue)?.label ?? (
            <span className="text-[#EBDFFF] opacity-50 text-sm font-sofia-light">
              {props.placeHolder ?? ''}
            </span>
          )}
          <div className={'scale-50 opacity-50'}>
            <ChevronDown />
          </div>
        </div>
      </div>
      {menuVisible && (
        <div
          className={`absolute ${direction === 'up' ? 'bottom-12' : 'top-12'} right-0 left-0 mt-0 mr-5 bg-gray-800 shadow-lg rounded-md z-10 max-h-48 overflow-y-auto w-full py-1`}
        >
          <div className="py-1 mx-3">
            {options.map((item, index) => (
              <>
                <div
                  key={index}
                  className="block px-0 py-2 text-sm text-white hover:bg-gray-700"
                  onClick={() => {
                    onValueChange(item.value);
                    toggleMenu();
                  }}
                >
                  {item.label}
                </div>
                {index === options.length - 1 ? null : (
                  <hr className="opacity-20" />
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;

import { Key, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EventCardItem } from 'components/eventCardItem';
import { EventObject } from 'components/eventCardItem/EventCardItem.types';
import { findEventOfDate, getSortedCalendarEvents } from 'utils/calendarUtils';
import { useGetMyEventsQuery } from 'services/event.service';
import InsideLoader from 'components/loader/InsideLoader';
import { MonthPill } from 'components/eventPagination/monthPill';
import InLineLoader from 'components/loader/InlineLoader';
import { MyEventPaginationProps } from './MyEventPagination.types';

const MyEventPagination = ({
  selectedDates,
  selectedEventType,
}: MyEventPaginationProps) => {
  const nextPageRef = useRef<number>(0);
  const [formattedEvents, setFormattedEvents] = useState<EventObject[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const {
    data: events,
    refetch: refetchEvent,
    isFetching,
    isLoading,
  } = useGetMyEventsQuery({ status: 1, page: nextPageRef.current });

  useEffect(() => {
    if (events) {
      if (events.length < 1) {
        setHasMore(false);
      }
      setFormattedEvents((prevList) =>
        getSortedCalendarEvents([...prevList, ...events]),
      );
    }
  }, [events]);

  // category pills
  const categoryPillsRef = useRef<string[]>([]);

  useEffect(() => {
    // clear the category pills on every render
    categoryPillsRef.current = [];
  });

  // scroll to the selected date related events
  useEffect(() => {
    if (selectedDates && selectedDates.length > 0 && events) {
      const found = findEventOfDate([...events], selectedDates[0]);
      if (found) {
        const element = document.getElementById(found.event?._id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [selectedDates, events]);

  const fetchNextPage = () => {
    if (isFetching) return;
    nextPageRef.current = nextPageRef.current + 1;
    refetchEvent();
  };

  return (
    <div>
      {isLoading && <InsideLoader />}
      {!isLoading && formattedEvents && !formattedEvents?.length && (
        <div className="text-center text-white flex justify-center items-center min-h-[calc(100dvh-300px)] w-full select-none">
          <p>No events found</p>
        </div>
      )}
      <InfiniteScroll
        dataLength={formattedEvents?.length}
        className="pb-2"
        loader={
          <div>
            <InLineLoader />
          </div>
        }
        next={fetchNextPage}
        hasMore={hasMore}
      >
        {formattedEvents?.map(
          (event: EventObject, index: Key | null | undefined) => (
            // <>
            // {/* Use for month pill */}
            // {/* <MonthPill pillRef={categoryPillsRef} event={event?.event} /> */}
            <div key={index} className="mb-2" id={event?.event?._id}>
              <EventCardItem
                id={event.event?._id}
                key={index}
                eventData={event.event}
                variant={'ongoing'}
                additionalVariants={event?.variantData}
              />
            </div>
            // </>
          ),
        )}
      </InfiniteScroll>
    </div>
  );
};

export default MyEventPagination;

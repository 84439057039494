import type React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IAvailabilityPeriod } from 'modules/profile/types/types';
import TimeSlotLayout from 'modules/profile/layouts/TimeSlotLayout/TimeSlotLayout';

import { AvailabilityPeriodModel } from 'modules/profile/model/Period';

import {
  isTimeAvailableOnDate,
  validateAvailabilityPeriods,
} from 'modules/profile/utils/availability';
import type { IStartAndEndTimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';

dayjs.extend(utc);
dayjs.extend(timezone);

type RecurringAvailabilitySlotProps = {
  slots: IAvailabilityPeriod[];
  onChange: Dispatch<SetStateAction<IAvailabilityPeriod[]>>;
  allEventData?: [];
  selectedDate?: Date;
  showSelectedInitially?: boolean;
};

const RecurringAvailabilitySlot: React.FC<RecurringAvailabilitySlotProps> = ({
  slots,
  onChange,
  allEventData,
  selectedDate,
  showSelectedInitially,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [addNewDisabled, setAddNewDisabled] = useState<boolean>(false);

  useEffect(() => {
    setError(null);

    if (slots.length < 2) return;

    if (validateAvailabilityPeriods(slots))
      setError('The time slot overlaps with an existing slot.');

    setAddNewDisabled(!isTimeAvailableOnDate(slots));
  }, [slots]);

  const handleDelete = (id: string) => {
    onChange((prev) => prev.filter((slot) => slot.id !== id));
  };

  const addSlot = () => {
    setError(null);
    try {
      if (isTimeAvailableOnDate(slots)) {
        const newSlot = new AvailabilityPeriodModel(slots);
        onChange([...slots, newSlot]);
      } else {
        setError(
          'Not enough time left in the 24-hour period to add a new availability period.',
        );
      }
    } catch (error) {
      setError(
        'Not enough time left in the 24-hour period to add a new availability period.',
      );
    }
  };

  const onTimeChange = (id: string, time: IStartAndEndTimeSlot) => {
    setError(null);
    const updatedSlots: IAvailabilityPeriod[] = slots.map((slot) => {
      if (slot.id === id) {
        return {
          ...slot,
          start: time.start,
          end: time.end,
        };
      }
      return slot;
    });

    onChange(updatedSlots);
  };

  return (
    <div className="flex flex-col w-full">
      {error && (
        <div className="text-destructive text-sm text-center font-semibold mb-4">
          {error}
        </div>
      )}
      <TimeSlotLayout
        periods={slots}
        onDelete={handleDelete}
        onAdd={addSlot}
        onTimeChange={onTimeChange}
        addDisabled={addNewDisabled}
        allEventData={allEventData}
        selectedDate={selectedDate}
        showSelectedInitially={showSelectedInitially}
      />
    </div>
  );
};

export default RecurringAvailabilitySlot;

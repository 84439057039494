import { RiUser3Fill } from '@remixicon/react';
import { useEffect, useRef, useState } from 'react';
import { Text } from 'components/text/primary/Text';
import { ReactComponent as TickIcon } from 'assets/images/icons/tick.svg';
import { ReactComponent as QuestionIcon } from 'assets/images/icons/question.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg';
import { ReactComponent as DotIcon } from 'assets/images/icons/dot.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import { IContact } from 'types/event';
import caleidoBaseApi from 'services/apiClient';

type ViewInviteListProps = {
  list: IContact[];
  eventId?: string;
};

const ViewInviteList: React.FC<ViewInviteListProps> = ({ list, eventId }) => {
  const [inviteeList, setInviteeList] = useState(() => {
    return list ? list.filter((c) => c.name.trim() != '') : [];
  });

  // TODO: uncomment to filter unique
  // const uniqueInviteeList = inviteeList.filter((i, index, self) => self.findIndex((t) => t.name === i.name) === index);
  const uniqueInviteeList = inviteeList;

  const status0 = uniqueInviteeList.filter(
    (i) => i.status === 0 && i.organizer === 0,
  );
  const status1 = uniqueInviteeList.filter(
    (i) => i.status === 1 && i.organizer === 0,
  );
  const status2 = uniqueInviteeList.filter(
    (i) => i.status === 2 && i.organizer === 0,
  );
  const status3 = uniqueInviteeList.filter(
    (i) => i.status === 3 && i.organizer === 0,
  );

  // fetch the list of invitees every 15 seconds
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (eventId) {
        getEvent(eventId)
          .then((res) => {
            if (res.data?.contacts) {
              const attendingInvitees =
                res.data?.contacts?.filter(
                  (contact: any) =>
                    contact.organizer === 0 && contact.name.trim() != '',
                ) ?? [];
              setInviteeList((prev) => attendingInvitees);
            }
          })
          .catch((err) => {});
      }
    }, 15000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  // get public view of the event from API
  const getEvent = async (eventId: string) => {
    return caleidoBaseApi.get(`/event/share/${eventId}`);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <Text size="small" className="text-base font-medium">
          Invited
        </Text>

        <div className="flex items-center gap-1 px-2.5 flex-row text-violet-700 bg-white rounded-xl">
          <RiUser3Fill size={12} />

          <Text size="small" className="text-violet-700 text-xs leading-none">
            {inviteeList?.length}
          </Text>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div>
          <div className="grid grid-cols-1 gap-2">
            {/* not responded list */}
            {status0.length > 0 && (
              <>
                {status0.map((i, index) => (
                  <div
                    key={index}
                    className="bg-[#aeaeae] text-slate-900 w-1/2 text-sm font-normal px-2 py-1 rounded-full text-center whitespace-nowrap text-ellipsis overflow-hidden flex items-center gap-2"
                  >
                    <InfoIcon className="w-3" />
                    <div className="overflow-hidden text-ellipsis">
                      {i.name}
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* accepted list */}
            {status1.length > 0 && (
              <>
                {status1.map((i, index) => (
                  <div
                    key={index}
                    className="bg-green-200 text-slate-900 w-1/2 text-sm font-normal px-2 py-1 rounded-full text-center whitespace-nowrap text-ellipsis overflow-hidden flex items-center gap-2"
                  >
                    <TickIcon />
                    <div className="overflow-hidden text-ellipsis">
                      {i.name}
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* maybe list */}
            {status3.length > 0 && (
              <>
                {status3.map((i, index) => (
                  <div
                    key={index}
                    className="bg-orange-200 text-slate-900 w-1/2 text-sm font-normal px-2 py-1 rounded-full text-center whitespace-nowrap text-ellipsis overflow-hidden flex items-center gap-2"
                  >
                    <QuestionIcon />
                    <div className="overflow-hidden text-ellipsis">
                      {i.name}
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* rejected list */}
            {status2.length > 0 && (
              <>
                {status2.map((i, index) => (
                  <div
                    key={index}
                    className="bg-red-100 text-slate-900 w-1/2 text-sm font-normal px-2 py-1 rounded-full text-center whitespace-nowrap text-ellipsis overflow-hidden flex items-center gap-2"
                  >
                    <CrossIcon />
                    <div className="overflow-hidden text-ellipsis">
                      {i.name}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInviteList;

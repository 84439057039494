import { forwardRef, TextareaHTMLAttributes } from 'react';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  className?: string;
  required?: boolean;
  error?: boolean | string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, className, required, error, ...props }, ref) => {
    return (
      <div className="relative flex flex-col w-full my-2">
        <div className="flex items-center justify-start space-x-2 mb-2">
          <Text size="small">{label}</Text>
          {required && <Text size="small">*</Text>}
        </div>
        <textarea
          className={cn(
            'w-full max-w-sm mx-auto font-sans text-sm font-medium text-gray-300 bg-white border rounded-xl bg-opacity-10 focus:outline-none px-4 py-2.5',
            className,
            `${error ? 'border-red-600' : 'border-slate-600'}`,
          )}
          ref={ref}
          {...props}
        />
        {error && (
          <Text size="extra-small" type="error" className="mt-1.5">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;

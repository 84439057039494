export const isPWA = () => {
  // Detect if running as a PWA (Android/Desktop)
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  // Detect if running as a PWA (iOS)
  const isIOSPWA =
    typeof navigator !== 'undefined' &&
    'standalone' in navigator &&
    // @ts-ignore - specific to iOS Safari
    navigator.standalone;

  // Return true if running as PWA on any platform
  return isStandalone || isIOSPWA || false;
};

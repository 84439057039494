import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import cuid from 'cuid';
import {
  EventItem,
  EventObject,
  TimeOffObject,
  TimeOffRecord,
} from 'components/eventCardItem/EventCardItem.types';
import { determineVariant } from 'utils/calendarUtils';
import { CalendarEvent } from 'modules/home/types/CalendarEvent';
import { GCalendarBlock } from 'modules/home/types/GCalendarBlock';

export const formatDateToLocalTimezone = (
  dateString: string,
  timezone: string,
) => {
  // Convert the UTC date string to a Date object
  const date = new Date(dateString);
  // Convert the date to the desired timezone
  const zonedDate = toZonedTime(date, timezone);
  // Format the date to get only the date part in YYYY-MM-DD format
  // const formattedDate = format(zonedDate, formatStr);
  return zonedDate;
};

export const getSearchParams = (url: string) => {
  const params: any = {};
  const queryString = url.split('?')[1];

  if (queryString) {
    const pairs = queryString.split('&');

    pairs.forEach((pair: any) => {
      const [key, value] = pair.split('=');
      params[key] = decodeURIComponent(value || '');
    });
  }

  return params;
};

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th'; // covers 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatWithOrdinal = (date: Date): string => {
  const day = format(date, 'd'); // Day of the month without leading zero
  const dayWithSuffix = day + getOrdinalSuffix(parseInt(day, 10));
  return `${dayWithSuffix} ${format(date, 'MMMM')}, ${format(date, 'yyyy')}`;
};

export const getWeekDates = (date: Date) => {
  // 0 (Sunday) to 6 (Saturday)
  const dayIndex = date.getDay();
  const startOfWeek = new Date(date);

  // adjust to the start of the week (Sunday) by default
  startOfWeek.setDate(date.getDate() - dayIndex);

  const weekDates = [];

  // create an array of 7 date objects for the week
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startOfWeek);
    currentDate.setDate(startOfWeek.getDate() + i);
    weekDates.push(currentDate);
  }

  return weekDates;
};

export const getWeekDatesAround = (date: Date) => {
  const weekDates = [];

  // Create an array of 7 date objects, 3 days before and 3 days after the given date
  for (let i = -3; i <= 3; i++) {
    const currentDate = new Date(date);
    currentDate.setDate(date.getDate() + i);
    weekDates.push(currentDate);
  }
  return weekDates;
};

// Function to get start and end times for the selected date
export const getDayStartAndEnd = (date: Date) => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0); // Set to 12:00 AM
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999); // Set to 11:59 PM
  return { startOfDay, endOfDay };
};

// Function to get start and end of the month for the selected date
export const getMonthStartAndEnd = (date: Date) => {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999,
  );
  return { startOfMonth, endOfMonth };
};

// Function to get start and end of the week for the selected date
export const getWeekStartAndEnd = (date: Date) => {
  const startOfWeek = new Date(date);
  const endOfWeek = new Date(date);

  // Set the start of the week (Sunday)
  startOfWeek.setDate(date.getDate() - date.getDay());
  startOfWeek.setHours(0, 0, 0, 0);

  // Set the end of the week (Saturday)
  endOfWeek.setDate(date.getDate() + (6 - date.getDay()));
  endOfWeek.setHours(23, 59, 59, 999);

  return {
    startOfWeek,
    endOfWeek,
  };
};

export const getYearStartAndEnd = (date: Date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const endOfYear = new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999);
  return { startOfYear, endOfYear };
};

export const getYearDayRange = (date: Date) => {
  const startOfRange = new Date(date);
  startOfRange.setDate(date.getDate() - 365);

  const endOfRange = new Date(date);
  endOfRange.setDate(date.getDate() + 365);

  return { startOfRange, endOfRange };
};

export const mapToCalendarEVents = (
  events: EventObject[] | undefined,
  timeOffs: TimeOffObject | undefined,
  gCalendarBlocks: GCalendarBlock[] | undefined,
) => {
  const mappedEvents: CalendarEvent[] = [];
  if (events) {
    events.forEach((event: EventObject) => {
      const e = event?.event;
      const v = event?.variantData;
      mappedEvents.push({
        id: e._id,
        start: new Date(e.start),
        end: new Date(e.end),
        title: e.activity,
        variant: determineVariant(v),
        eventData: e,
        variantData: v,
      });
    });
  }

  // TODO: Refactor this after time off API is updated
  if (timeOffs && timeOffs.timeOffRecords) {
    const timeOffRecords = timeOffs?.timeOffRecords;
    timeOffRecords.forEach((timeOff: TimeOffRecord) => {
      const e = timeOff;
      const ei: Partial<EventItem> = {};
      ei.activity = (e as any)?.title ?? 'busy';

      mappedEvents.push({
        id: e._id,
        start: new Date(e.start),
        end: new Date(e.end),
        title: (e as any)?.title ?? 'busy',
        variant: 'blocked',
        eventData: ei as EventItem,
        isDeletable: true,
      });
    });
  }

  if (gCalendarBlocks && gCalendarBlocks.length > 0) {
    gCalendarBlocks.forEach((block: GCalendarBlock) => {
      if (!block.start?.dateTime || !block.end?.dateTime) return;
      const e = block;
      const ei: Partial<EventItem> = {};
      ei.activity = 'G-Cal';
      mappedEvents.push({
        id: cuid(),
        start: new Date(e.start.dateTime),
        end: new Date(e.end.dateTime),
        title: 'G-Cal',
        variant: 'blocked',
        eventData: ei as EventItem,
        isDeletable: false,
      });
    });
  }

  return mappedEvents;
};

export const filterDuplicateCalendarEvents = (events: CalendarEvent[]) => {
  return events.reduce((acc: CalendarEvent[], event: CalendarEvent) => {
    if (!acc.find((e) => e.id === event.id && e.start === event.start)) {
      acc.push(event);
    }
    return acc;
  }, []);
};

export const filterDuplicateEvents = (events: EventObject[]) => {
  return events.reduce((acc: EventObject[], event: EventObject) => {
    if (!acc.find((e) => e.event._id === event.event._id)) {
      acc.push(event);
    }
    return acc;
  }, []);
};

import { ReceiveMeetResponseState } from 'modules/letsMeet/types/types';

interface EventOrganizerHeaderProps {
  organizerPhoto: string;
  organizerName: string;
  sentTime: string;
  responseState?: ReceiveMeetResponseState;
  isHost: boolean;
  isPast?: boolean;
  eventType?: string;
  hasUserVoted?: boolean;
}

const EventOrganizerHeader: React.FC<EventOrganizerHeaderProps> = ({
  organizerPhoto,
  organizerName,
  sentTime,
  responseState,
  isHost,
  isPast,
  eventType,
  hasUserVoted,
}) => {
  const getStatusInfo = () => {
    if (isHost) {
      return { text: 'Created by you', emoji: null };
    }

    if (eventType === 'poll' && hasUserVoted) {
      return { text: 'You responded!', emoji: '🗳️' };
    } else if (eventType === 'poll' && !hasUserVoted) {
      return { text: `${organizerName} wants to find a time`, emoji: null };
    }

    if (isPast) {
      switch (responseState) {
        case ReceiveMeetResponseState.ACCEPTED:
          return { text: 'You went!', emoji: '👍' };
        case ReceiveMeetResponseState.DECLINED:
          return { text: "Couldn't make it", emoji: '😢' };
        case ReceiveMeetResponseState.MAYBE:
          return { text: 'Maybe went', emoji: '🤔' };
        case ReceiveMeetResponseState.NOT_RESPONDED:
        case ReceiveMeetResponseState.EXPIRED:
          return { text: 'You did not respond', emoji: null };
        default:
          return { text: `${organizerName} wanted you to join`, emoji: null };
      }
    }

    switch (responseState) {
      case ReceiveMeetResponseState.ACCEPTED:
        return { text: 'You are going!', emoji: '👍' };
      case ReceiveMeetResponseState.DECLINED:
        return { text: 'You declined', emoji: '😢' };
      case ReceiveMeetResponseState.MAYBE:
        return { text: 'Maybe going', emoji: '🤔' };
      default:
        return { text: `${organizerName} wants you to join`, emoji: null };
    }
  };

  const { text, emoji } = getStatusInfo();
  const showEmoji =
    (!isHost &&
      responseState !== ReceiveMeetResponseState.NOT_RESPONDED &&
      responseState !== ReceiveMeetResponseState.EXPIRED) ||
    (eventType === 'poll' && hasUserVoted);

  const renderAvatar = () => {
    if (showEmoji && emoji) {
      return <span className="text-2xl">{emoji}</span>;
    }

    if (organizerPhoto && !organizerPhoto.match(/^[A-Za-z]$/)) {
      return (
        <img
          src={organizerPhoto}
          alt={organizerName}
          className="w-[48px] h-[48px] rounded-full object-cover"
        />
      );
    }

    return (
      <div className="w-[48px] h-[48px] bg-[#32395E] rounded-full flex items-center justify-center">
        <span className="text-[24px] font-medium text-white leading-none">
          {organizerName[0]?.toUpperCase() || 'U'}
        </span>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center gap-2 mt-2">
      {isPast && (
        <p className="text-red-500 text-center font-manrope text-sm font-medium mb-2">
          This event is in the past
        </p>
      )}
      <div className="flex p-[14px] justify-center items-center rounded-[100px] bg-[#1A1F37]">
        {renderAvatar()}
      </div>
      <div className="flex flex-col items-center gap-1">
        <h2 className="text-white text-center font-manrope text-base font-semibold">
          {text}
        </h2>
        {!isHost && (
          <p className="text-white/80 text-center font-manrope text-base font-normal">
            {responseState === ReceiveMeetResponseState.NOT_RESPONDED ||
            responseState === ReceiveMeetResponseState.EXPIRED
              ? eventType === 'poll'
                ? 'Pending date'
                : sentTime
              : eventType === 'poll'
                ? 'Pending date'
                : 'Created by ' + organizerName}
          </p>
        )}
      </div>
    </div>
  );
};

export default EventOrganizerHeader;

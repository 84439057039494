export enum GuestNotificationType {
  MEET_NOW = 'MEET_NOW',
  MEET_LATER = 'MEET_LATER',
  POLL = 'POLL',
  NOTIFY_DAILY_POLL = 'NOTIFY_DAILY_POLL',
  NOTIFY_HOURLY_POLL = 'NOTIFY_HOURLY_POLL',
  NOTIFY_ON_DAY_POLL = 'NOTIFY_ON_DAY_POLL',
  FIXED_POL = 'FIXED_POL',
}

export enum HostNotificationType {
  COMMENT_NOTIFY = 'COMMENT_NOTIFY', // Notification for a new comment
  VOTE_POLL = 'VOTE_POLL', // Notification for a vote on a poll
  REMIND_POLL = 'REMIND_POLL', // Reminder for an upcoming poll
  RSVP_POLL = 'RSVP_POLL',
}

export enum RSVPStatusType {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
}

export enum NotificationType {
  // Host Notifications
  RSVP = 'RSVP',
  COMMENT = 'COMMENT',
  POLL_VOTE = 'POLL_VOTE',
  REMINDER_TO_PICK_DATE = 'REMINDER_TO_PICK_DATE',

  // Guest Notifications
  INVITE_MEET_NOW = 'INVITE_MEET_NOW',
  INVITE_MEET_LATER = 'INVITE_MEET_LATER',
  POLL_REQUEST = 'POLL_REQUEST',
  REMINDER_TO_RESPOND_TO_POLL = 'REMINDER_TO_RESPOND_TO_POLL',
  DAY_OF_EVENT_REMINDER = 'DAY_OF_EVENT_REMINDER',
  TWO_HOURS_BEFORE_EVENT_REMINDER = 'TWO_HOURS_BEFORE_EVENT_REMINDER',
  POLL_TURNED_INTO_DATE = 'POLL_TURNED_INTO_DATE',
  EVENT_CHANGE = 'EVENT_CHANGE',
  HAPPENING_NOW = 'HAPPENING_NOW',
}

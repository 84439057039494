import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import OverlayLoader from 'components/loader/OverlayLoader';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';

const SharePage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (user === undefined && localStorage.getItem('refreshToken') !== null) {
        return; // Wait for user state to be determined
      }

      if (user === null || localStorage.getItem('refreshToken') === null) {
        navigate(`/public/${id}`);
      } else {
        navigate(`/lets-meet/receive/${id}`);
      }
      setLoading(false);
    }, 300);

    return () => clearTimeout(redirectTimer);
  }, [user, navigate, id]);

  return <OnboardingLayout>{loading && <OverlayLoader />}</OnboardingLayout>;
};

export default SharePage;

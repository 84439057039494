import { ChevronRightIcon } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import OneSignal from 'react-onesignal';
import { Link } from 'react-router-dom';
import { toast } from 'components/ui/toast/use-toast';
import { ReactComponent as GreenBell } from 'assets/images/event/greenBell.svg';
import {
  GuestNotificationType,
  HostNotificationType,
  RSVPStatusType,
} from './enum';

type GuestNotificationPayload = {
  type: string;
  url?: string;
  name?: string;
  description?: string;
  count?: number;
  date?: string; // Adjust the type if `date` is in another format, e.g., `string`
  mainType?: string;
};

type HostNotificationPayload = {
  type: string;
  url?: string;
  name: string;
  comment?: string;
  count?: number;
  rsvpType?: string;
  mainType?: string;
};

type EventNotificationPayload = {
  name?: string;
  description?: string;
  url?: string;
};

type NotificationPayload = {
  payload: {
    notification: {
      title: string;
      body: string;
    };
    data: {
      type?: string;
      url?: string;
      name?: string;
      comment?: string;
      count?: number;
      rsvpType?: string;
      description?: string;
      date?: string;
      mainType?: string;
    };
  };
};

type LetsMeetNotificationProps = {
  name: string;
  onAction: () => void;
  message: ReactNode;
};

const guestNotificationGenerator = (
  payload: GuestNotificationPayload,
): ReactNode => {
  const name = <span className="font-bold">{payload.name}</span>;

  if (payload.type === GuestNotificationType.MEET_NOW) {
    return (
      <>
        {name} wants to meet now for {payload.description}
      </>
    );
  } else if (payload.type === GuestNotificationType.MEET_LATER) {
    return (
      <>
        {name} has invited you to {payload.description}
      </>
    );
  } else if (payload.type === GuestNotificationType.POLL) {
    return (
      <>
        {name} wants to know when you are free for {payload.description}
      </>
    );
  } else if (payload.type === GuestNotificationType.NOTIFY_DAILY_POLL) {
    return (
      <>
        Reminder: Let {name} know when you are free for {payload.description}
      </>
    );
  } else if (payload.type === GuestNotificationType.NOTIFY_ON_DAY_POLL) {
    return (
      <>
        Reminder: {payload.description} with {name} and {payload.count} others
        is today!
      </>
    );
  } else if (payload.type === GuestNotificationType.NOTIFY_HOURLY_POLL) {
    return <>Reminder: 2 hours until {payload.description}</>;
  } else if (payload.type === GuestNotificationType.FIXED_POL) {
    return (
      <>
        {name} Picked a date for {payload.description} - {payload.date}
      </>
    );
  } else {
    return null;
  }
};

const hostNotificationGenerator = (
  payload: HostNotificationPayload,
): ReactNode => {
  const name = <span className="font-bold">{payload.name}</span>;

  if (payload.type === HostNotificationType.RSVP_POLL) {
    if (payload.rsvpType === RSVPStatusType.YES) {
      return <>{name} is going</>;
    } else if (payload.rsvpType === RSVPStatusType.NO) {
      return <>{name} can’t make it</>;
    } else if (payload.rsvpType === RSVPStatusType.MAYBE) {
      return <>{name} says maybe</>;
    } else {
      return null;
    }
  } else if (payload.type === HostNotificationType.VOTE_POLL) {
    return <>{name} sent their availability</>;
  } else if (payload.type === HostNotificationType.COMMENT_NOTIFY) {
    return (
      <>
        {name} says {payload.comment}
      </>
    );
  } else if (payload.type === HostNotificationType.REMIND_POLL) {
    return <>Guests have submitted times, pick a date to meet!</>;
  } else {
    return null;
  }
};

const eventNotificationGenerator = (
  payload: EventNotificationPayload,
): ReactNode => {
  const name = <span className="font-bold">{payload.name}</span>;

  return (
    <>
      {name} has updated the event {payload.description}
    </>
  );
};

function Notifications() {
  const [initialized, setInitialized] = useState(false);
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      eventListener,
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        'foregroundWillDisplay',
        eventListener,
      );
    };
  }, []);

  const eventListener = (event: any): void => {
    setNotifications(event?.notification?.body);
    toast({
      title: '',
      description: <div>{event?.notification?.body}</div>,
      fullscreen: true,
      backgroundColor: '#E0F4DB',
      textColor: '#060606',
      icon: <GreenBell />,
      action: event?.notification?.launchURL ? (
        <Link
          to={event?.notification?.launchURL}
          className="font-bold flex items-center gap-0.5"
        >
          Respond <ChevronRightIcon size={12} />
        </Link>
      ) : (
        <></>
      ),
    });
  };

  return <Toaster />;
}

export default Notifications;

import { format, toZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';

interface IDateInfo {
  date: Date;
  startTime: Date;
  endTime: Date;
}

export const formatDateToLocalTimezone = (
  dateString: string,
  formatStr: string,
  timezone: string = getUserTimezone(),
) => {
  try {
    const date = new Date(dateString);
    const zonedDate = toZonedTime(date, timezone);
    const formattedDate = format(zonedDate, formatStr);
    return formattedDate;
  } catch (e) {
    return 'Invalid time';
  }
};

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDateTimeSlot = (timeSlot: string) => {
  try {
    const [year, month, day, startTime, endTime] = timeSlot.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const startDate = new Date(`${date.toDateString()} ${startTime}`);
    const endDate = new Date(`${date.toDateString()} ${endTime}`);

    return {
      date,
      startTime: startDate,
      endTime: endDate,
    } as IDateInfo;
  } catch (e) {
    return {
      date: new Date(),
      startTime: new Date(),
      endTime: new Date(),
    };
  }
};

export const generateTimeOptions = () => {
  const times = [
    { value: 30, label: '30 minutes' },
    { value: 60, label: '1 hour' },
    { value: 120, label: '2 hours' },
    { value: 180, label: '3 hours' },
    { value: 240, label: '4 hours' },
    { value: 300, label: '5 hours' },
    { value: 360, label: '6 hours' },
    { value: 360, label: '6+ hours' }, // We show this option as 6 plus hours but for now cap it at 6 hours.
  ];

  // Generate options in the required format
  return times.map(({ value, label }) => ({
    label,
    value: value.toString(),
  }));
};

export const toNextDay = (date?: Date, add?: boolean) => {
  if (!add) return date;
  if (!date) return new Date();

  const newDate = dayjs(date).add(1, 'day');
  return newDate.toDate();
};

export const isTimePassed = (dateTimeString: string) => {
  try {
    const parts = dateTimeString?.split('-');
    const datePart = parts?.slice(0, 3).join('-');
    const startTime = parts.length > 2 ? parts[3] : null;
    if (startTime === null) {
      return false;
    }
    const formattedString = `${datePart}T${startTime}`;
    const givenDate = new Date(formattedString);
    const currentDate = new Date();

    return givenDate < currentDate;
  } catch (e) {
    return false;
  }
};

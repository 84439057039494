import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { CheckIcon } from 'lucide-react';
import axios from 'axios';
import { IPollData, TimeSlotAvailability } from 'types/event';

interface PollResultsProps {
  pollData: IPollData | undefined;
  totalUsers: number;
  showCreatorView?: boolean;
  onSelectTimeSlot?: (index: number | null) => void;
  eventId?: string;
}

// Helper function for checking if time has passed
export const isTimePassed = (timeString: string): boolean => {
  const match = timeString.match(/^(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})/);
  if (!match) return false;

  const [_, date, time] = match;
  const dateTime = new Date(`${date}T${time}Z`);
  return dateTime < new Date();
};

const PollResults: React.FC<PollResultsProps> = ({
  pollData,
  totalUsers,
  showCreatorView,
  onSelectTimeSlot,
  eventId,
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | null>(null);
  const [availabilities, setAvailabilities] = useState<TimeSlotAvailability>(
    {},
  );
  const [loading, setLoading] = useState(true);

  // Check availabilities
  // useEffect(() => {
  //   const checkAvailabilities = async () => {
  //     try {
  //       if (!pollData?.checkedTimes?.length) return;
  //       const accessToken = sessionStorage.getItem('accessToken');
  //       const timeslots = pollData.checkedTimes.map(slot => slot.time);
  //       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/event/availability-check`,
  //         { timeslots, eventId },
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${accessToken}`
  //           }
  //         }
  //       );
  //       setAvailabilities(response.data);
  //     } catch (error) {
  //       console.error('Error checking availabilities:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   checkAvailabilities();
  // }, [pollData]);

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM d');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`);
      return format(date, 'h:mm a');
    } catch (error) {
      console.error('Error parsing time:', error);
      return 'Invalid Time';
    }
  };

  const handleTimeSlotSelect = (index: number) => {
    const newSelectedTimeSlot = selectedTimeSlot === index ? null : index;
    setSelectedTimeSlot(newSelectedTimeSlot);
    if (onSelectTimeSlot) {
      onSelectTimeSlot(newSelectedTimeSlot);
    }
  };

  return (
    <div className="flex flex-col items-start gap-2 rounded-xl bg-[#1A1F37] py-4 w-full">
      <div className="flex flex-col gap-1 px-4">
        <h2 className="text-white font-semibold text-sm font-manrope">
          Confirm a time to meet
        </h2>
        {showCreatorView && (
          <p className="text-white/80 text-[13px] font-normal font-manrope">
            Select a time slot
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2 w-full">
        {pollData?.checkedTimes.map((timeSlot, index) => {
          const voterCount = timeSlot.voters.length || 0;
          const isPassed = isTimePassed(timeSlot.time);
          const availability = availabilities[timeSlot.time];

          const matchResult = timeSlot.time.match(
            /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
          );
          const [date, startTime] = matchResult
            ? [matchResult[1], matchResult[2] + (matchResult[3] || '')]
            : ['', ''];

          return (
            <div
              key={index}
              className={`relative px-4 py-3 cursor-pointer ${isPassed ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() =>
                !isPassed && showCreatorView && handleTimeSlotSelect(index)
              }
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-3">
                  {showCreatorView && (
                    <div className="flex-shrink-0 h-[34px] flex items-center">
                      <div
                        className={`flex justify-center items-center w-[18px] h-[18px] rounded-[2px] ${
                          selectedTimeSlot === index
                            ? 'bg-[#ABE49C] border-2 border-[#ABE49C]'
                            : 'border-2 border-white'
                        }`}
                      >
                        {selectedTimeSlot === index && (
                          <CheckIcon className="w-4 h-4 text-[#110E2B]" />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col">
                    <span className="text-white font-manrope text-sm">
                      {`${formatDate(date)} at ${formatTime(date, startTime)}`}
                    </span>
                    <span className="text-white/60 font-manrope text-sm tracking-wide">
                      {voterCount} Voted
                    </span>
                  </div>
                </div>
                {/* {!loading && availability && (
                  <span className={`font-manrope text-sm font-medium leading-6 tracking-wide ${
                    availability.availability ? 'text-[#ABE49C]' : 'text-[#FF6A62]'
                  }`}>
                    {availability.availability ? 'Available' : ''}
                  </span>
                )} */}
              </div>

              {showCreatorView &&
                selectedTimeSlot === index &&
                timeSlot.voters.length > 0 && (
                  <div className="mt-4 ml-[34px]">
                    <h3 className="text-white font-manrope text-sm font-medium mb-2">
                      Your friends who voted
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {timeSlot.voters.map((voter, voterIndex) => (
                        <div
                          key={voterIndex}
                          className="flex items-center gap-2"
                        >
                          <div className="w-8 h-8 rounded-full bg-[#ABE49C]/10 text-[#ABE49C] flex items-center justify-center text-sm font-medium">
                            {voter?.name?.charAt(0)?.toUpperCase() || '?'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PollResults;

import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'lucide-react';
import { cn } from 'utils/helpers';
import { Button } from 'components/ui/button';

interface ButtonProps {
  children: React.ReactNode;
  type: 'primary' | 'secondary';
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactNode | undefined;
  to?: string;
}

const types: { [key in ButtonProps['type']]: string } = {
  primary: 'bg-secondary',
  secondary: 'bg-white border border-opacity-20 bg-opacity-10 border-slate-300',
};

const MainButton: React.FC<ButtonProps> = ({
  children,
  type,
  className,
  onClick,
  loading,
  disabled = false,
  icon,
  to,
}) => {
  const navigate = useNavigate();
  const baseClass =
    'flex items-center justify-between w-full px-8 py-6 rounded-full text-white';
  const typeClass = types[type] || '';

  return (
    <Button
      className={`${cn(baseClass, typeClass, className)}`}
      onClick={() => (to ? navigate(to) : onClick?.())}
      disabled={loading || disabled}
    >
      {children}
      {loading ? <Loader className="animate-spin" /> : icon}
    </Button>
  );
};

export default MainButton;

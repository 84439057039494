import { useEffect } from 'react';
import { AddManualBlock } from 'modules/home/components/addManualBlock';
import BottomNav, { BottomNavType } from 'components/bottomNav/bottomNav';
import { ViewEventPop } from 'components/viewEventPop';

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import {
  makeBlockTimeOffsFullWidth,
  makeEventCardsFullWidth,
} from 'utils/calendarUtils';
import { BigCalendarContainerProps } from './BigCalendarContainer.type';

const BigCalendarContainer = ({
  children,
  addManualBlockData,
  selectedViewEvent,
  drawerContent,
  onDrawerActionCancel,
  onDrawerActionConfirm,
  onDrawerClick,
  onDrawerClickAway,
  onDrawerClose,
  onDrawerOpen,
  onDrawerActionComplete,
  origin,
  variant,
}: BigCalendarContainerProps) => {
  useEffect(() => {
    makeBlockTimeOffsFullWidth();
    variant === 'daily' && makeEventCardsFullWidth();
  }, [children]);

  return (
    <>
      {children}

      {/* drawer*/}
      <Drawer
        open={addManualBlockData || selectedViewEvent ? true : false}
        onClose={() => {
          onDrawerClose && onDrawerClose();
        }}
        repositionInputs={false}
        modal={false}
      >
        <DrawerContent className=" bg-[#121538] px-0 mt-0 bottom-0 pb-12">
          <DrawerHeader className="hidden">
            <DrawerTitle></DrawerTitle>
            <DrawerDescription></DrawerDescription>
          </DrawerHeader>
          {addManualBlockData ? (
            <AddManualBlock
              startTime={addManualBlockData?.start}
              endTime={addManualBlockData?.end}
              title={addManualBlockData?.title}
              onClose={onDrawerClose}
              onSuccess={() => {
                onDrawerActionComplete && onDrawerActionComplete('timeOff');
                onDrawerClose && onDrawerClose();
              }}
            />
          ) : selectedViewEvent ? (
            <ViewEventPop
              event={selectedViewEvent}
              onClose={onDrawerClose}
              onActionCancel={(variant) => {
                onDrawerActionCancel && onDrawerActionCancel(variant);
                onDrawerClose && onDrawerClose();
              }}
              onActionConfirm={(variant) => {
                onDrawerActionComplete && onDrawerActionComplete(variant);
                onDrawerClose && onDrawerClose();
              }}
              origin={origin}
            />
          ) : (
            <></>
          )}
        </DrawerContent>
      </Drawer>

      {/* bottom navigation */}
      <BottomNav type={BottomNavType.HOME} />
    </>
  );
};

export default BigCalendarContainer;

import React, { useState, useEffect } from 'react';
import type { ChangeEvent } from 'react';
import { isEmpty } from 'lodash';
import { Loader } from 'lucide-react';
import heic2any from 'heic2any';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';

// Types
type HeicConversionOptions = {
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
};

type ProfileAvatarProps = {
  uploadable?: boolean;
  disableDelete?: boolean;
  onUpload?: (file: File, optimize?: boolean) => Promise<void>;
  onDelete?: () => Promise<void>;
  className?: string;
  loading?: boolean;
  error?: boolean;
  url?: string;
  size?: number;
  hideBorder?: boolean;
};

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  uploadable,
  onUpload,
  onDelete,
  className,
  loading,
  url,
  size = 100,
  disableDelete,
  hideBorder,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Extend the heic2any options type
  type HeicOptions = Parameters<typeof heic2any>[0] & {
    maxWidth?: number;
    maxHeight?: number;
  };

  const convertHeicToJpeg = async (
    blob: Blob,
    options: HeicConversionOptions = {},
  ): Promise<Blob> => {
    try {
      // First convert HEIC to JPEG
      const convertedBlob = await heic2any({
        blob,
        toType: 'image/jpeg',
        quality: options.quality || 0.95,
      } as HeicOptions);

      const resultBlob = Array.isArray(convertedBlob)
        ? convertedBlob[0]
        : convertedBlob;

      // Resize the image if needed
      if (options.maxWidth || options.maxHeight) {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Create a promise to handle the image loading
        const resizedBlob = await new Promise<Blob>((resolve, reject) => {
          img.onload = () => {
            try {
              let width = img.width;
              let height = img.height;

              // Calculate new dimensions while maintaining aspect ratio
              if (options.maxWidth && width > options.maxWidth) {
                height = (height * options.maxWidth) / width;
                width = options.maxWidth;
              }

              if (options.maxHeight && height > options.maxHeight) {
                width = (width * options.maxHeight) / height;
                height = options.maxHeight;
              }

              canvas.width = width;
              canvas.height = height;

              ctx?.drawImage(img, 0, 0, width, height);

              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    resolve(blob);
                  } else {
                    reject(new Error('Failed to create blob from canvas'));
                  }
                },
                'image/jpeg',
                options.quality || 0.95,
              );
            } catch (error) {
              reject(error);
            }
          };

          img.onerror = () =>
            reject(new Error('Failed to load image for resizing'));

          // Create object URL from the blob
          img.src = URL.createObjectURL(resultBlob);
        });

        return resizedBlob;
      }

      return resultBlob;
    } catch (error) {
      console.error('HEIC conversion failed:', error);
      throw new Error(
        'Failed to convert HEIC image. Please try another image format.',
      );
    }
  };

  const processImageFile = async (file: File): Promise<File> => {
    const isHeic =
      file.type === 'image/heic' ||
      file.type === 'image/heif' ||
      file.name.toLowerCase().endsWith('.heic') ||
      file.name.toLowerCase().endsWith('.heif');

    if (isHeic) {
      try {
        const convertedBlob = await convertHeicToJpeg(file, {
          quality: 0.95,
          maxWidth: 2000,
          maxHeight: 2000,
        });

        return new File(
          [convertedBlob],
          file.name.replace(/\.(heic|heif)$/i, '.jpg'),
          { type: 'image/jpeg' },
        );
      } catch (error) {
        console.error('HEIC conversion error:', error);
        throw error;
      }
    }

    // For non-HEIC images
    const blob = new Blob([file], { type: file.type });
    return new File([blob], file.name, { type: file.type });
  };

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file) {
      if (file.size > maxSize) {
        setErrorMessage('File size exceeds 10MB');
        return;
      }

      try {
        setErrorMessage(null);
        setIsUploading(true);

        const processedFile = await processImageFile(file);
        await onUpload?.(processedFile, true);

        // Show preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
          setIsUploading(false);
        };
        reader.readAsDataURL(processedFile);
      } catch (error) {
        console.error(error);
        setImage(null);
        setIsUploading(false);
        if (error instanceof Error) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('Error uploading image!');
        }
      }
    }
  };

  const handleImageDelete = async () => {
    try {
      await onDelete?.();
      setImage(null);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error deleting image!');
    }
  };

  return (
    <div className={cn('relative flex flex-col items-center gap-2', className)}>
      <div
        className={`flex items-center justify-center bg-purple-500 rounded-full`}
        style={{
          width: size,
          height: size,
          backgroundColor: 'white',
        }}
      >
        {url || !isEmpty(url) ? (
          <>
            {loading || isUploading ? (
              <Loader className="animate-spin" color="#6000E9" size={22} />
            ) : (
              <div
                className={cn(
                  'relative rounded-full',
                  onDelete && 'overflow-hidden',
                )}
              >
                {onDelete && (
                  <div
                    className={cn(
                      'absolute inset-0 flex items-center justify-center bg-black/80 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-full w-full h-full z-20',
                      disableDelete && 'hidden',
                    )}
                    onClick={handleImageDelete}
                    onKeyDown={(e) => e.key === 'Enter' && handleImageDelete()}
                    role="button"
                    tabIndex={0}
                  >
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
                <img
                  src={
                    image ? image : url ? `${url}?refresh=${Math.random()}` : ''
                  }
                  alt="Profile Avatar"
                  className={cn(
                    'rounded-full object-cover relative z-10 bg-white',
                    hideBorder && 'z-0 static',
                  )}
                  style={{
                    width: size,
                    height: size,
                    objectFit: 'cover',
                  }}
                  onError={(e) => {
                    setImageError(true);
                    console.error('Image load error:', e);
                  }}
                />
                {!onDelete && !hideBorder && (
                  <div
                    className="absolute bg-[#6000E9] top-1/2 transform -left-[3px] -translate-y-1/2 rounded-full"
                    style={{
                      height: `${size + 6}px`,
                      width: `${(size + 12) / 2}px`,
                      borderRadius: `${size + 15}px 0 0 ${size + 15}px`,
                    }}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div
            className="flex items-center justify-center bg-white rounded-full"
            style={{ width: size - 4, height: size - 4 }}
          >
            {loading || isUploading ? (
              <Loader className="animate-spin" color="#6000E9" size={22} />
            ) : (
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="w-8 h-8"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            )}
          </div>
        )}

        {uploadable && (
          <label
            className="absolute right-0 cursor-pointer bottom-[4%] z-20"
            htmlFor="avatar-upload"
          >
            <div className="p-2 bg-white rounded-full shadow-lg">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="w-5 h-5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <input
              type="file"
              id="avatar-upload"
              accept="image/*,.heic,.heif"
              className="hidden"
              onChange={handleImageChange}
              disabled={loading || isUploading}
            />
          </label>
        )}
      </div>

      {errorMessage && (
        <Text size="small" type="error" className="mt-2">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default ProfileAvatar;

import { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { whoAmIAction } from 'modules/auth/slices/actions';
import OverlayLoader from 'components/loader/OverlayLoader';

import AuthRoutes from 'components/routes/AuthTree';
import BaseTree from 'components/routes/BaseTree';
import NotFound from 'modules/auth/pages/404/NotFound';
import LetsMeetRoutes from 'components/routes/LetsMeet';
import SettingsTree from './components/routes/SettingsTree';
import Terms from './modules/auth/pages/terms/Terms';
import About from './modules/auth/pages/about/About';

import './App.css';
import 'assets/styles/variables.css';
import 'assets/fonts/fonts.css';
import VisitLogin from './VisitLogin';
import HomePage from './components/HomePage';
import ActivitiesRoutes from 'components/routes/ActivityTree';
import { requestPermissionAndGetToken } from './firebaseConfig';
import Notifications from 'modules/letsMeet/utils/notification';
import { toast } from 'components/ui/toast/use-toast';
import { Button } from 'components/ui/button';
import SharePage from './modules/recieveEvent/SharePage';
import ReceivePublicEvent from './modules/letsMeet/pages/receivePublicEvent/receivePublicEvent';
import HomeRoutes from 'components/routes/HomeTree';
import { ReactComponent as GreenBell } from 'assets/images/event/greenBell.svg';
import { Toaster } from 'components/ui/toast/toaster';
import { IUser } from 'modules/auth/types/types';
import 'react-loading-skeleton/dist/skeleton.css';
import OneSignal from 'react-onesignal';
import { isTokensAvailable } from 'utils/authUtils';
import { publicRoutes } from 'modules/auth/constants/constants';
import { useValidateAuth } from 'hooks/useValidateAuth';
import { HelmetProvider } from 'react-helmet-async';
import { getDomainType } from 'utils/helpers';
import PrivacyPolicy from 'modules/auth/pages/privacy-policy/PrivacyPolicy';
import LandingPage from 'modules/landing/pages/MainPage';
import { isPWA } from 'utils/isPWA';
import { notificationService } from 'utils/notifications';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { useUpdateUserSettingsMutation } from 'services/slices/usersApiSlice';
import { webPushNotifications } from 'utils/webPushNotifications';
import { AuthService } from 'modules/auth/services/auth.service';

function App() {
  const { loading, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [updateUser, { isLoading: updateUserIsLoading, data }] =
    useUpdateUserSettingsMutation();

  const initialized = useRef(false);
  // validate auth
  const { isValidAuth } = useValidateAuth();

  const isPrivacyOrTOCPage =
    window.location.pathname.includes('privacy') ||
    window.location.pathname.includes('terms');
  const domainType = getDomainType();
  const isRunningAsPWA = isPWA();

  console.log('isPWA', isRunningAsPWA);

  useEffect(() => {
    if (!loading && user && user.availabilityTimezone) {
      if (
        new Intl.DateTimeFormat().resolvedOptions().timeZone !==
        user.availabilityTimezone
      ) {
        // toast({
        //   title:
        //     'Your Time zone has changed. Please update your availability settings to make the app functions smoothly',
        //   action: (
        //     <Button
        //       onClick={() => {}}
        //       className="text-sm text-white"
        //       variant="secondary"
        //     >
        //       <Link to="/settings/edit/availability">Update</Link>
        //     </Button>
        //   ),
        // });
      }
    }
  }, [loading]);
  const navigate = useNavigate();
  const setupRef = useRef(false); // Add this outside the effect
  useEffect(() => {
    const initAuth = async () => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        const refreshToken = sessionStorage.getItem('refreshToken');

        if (!accessToken && refreshToken) {
          try {
            // Actually call refresh token endpoint
            await AuthService.refreshTokens(refreshToken);
            // This should set new access token in sessionStorage
          } catch (error) {
            console.error('Token refresh failed:', error);
            // Clear tokens and redirect to login
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            navigate('/auth/login');
            return;
          }
        }

        // Check again for access token after potential refresh
        const currentAccessToken = sessionStorage.getItem('accessToken');
        if (currentAccessToken) {
          dispatch(whoAmIAction());
        } else {
          // Actually redirect to login
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Auth initialization failed:', error);
        navigate('/auth/login');
      }
    };

    initAuth();
  }, [dispatch, navigate]);
  // Second effect - handle notifications, but wait for auth to complete
  useEffect(() => {
    const setupNotifications = async () => {
      // Only proceed if we have user and auth loading is complete
      if (!user?._id || loading) {
        console.log('No user or auth loading in progress');
        return;
      }

      if (setupRef.current) {
        console.log('Notifications already setup');
        return;
      }

      try {
        setupRef.current = true;
        console.log('Setting up web push notifications...');
        await webPushNotifications.requestPermission(user._id);

        // Update PWA mode if needed
        if (isPWA()) {
          await updateUser({
            data: {
              usingPWAMode: true,
            } as any,
          }).unwrap();
        }
      } catch (error) {
        console.error('Failed to setup web push notifications:', error);
        setupRef.current = false; // Allow retry on error
      }
    };

    setupNotifications();
  }, [user, loading, updateUser]);
  if (domainType === 'landing' && !isRunningAsPWA && !isPrivacyOrTOCPage) {
    return <LandingPage />;
  }

  // if (isRunningAsPWA && !isValidAuth) {
  //   return <Navigate to="/auth/start" replace />;
  // }

  return (
    <HelmetProvider>
      <>
        <Notifications />
        <Routes>
          <Route path="/share/:id" element={<SharePage />} />
          <Route path="/public/:id" element={<ReceivePublicEvent />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="about" element={<About />} />
          <Route path="auth/*" element={<AuthRoutes />} />
          <Route
            path="auth/"
            element={
              <>
                <Navigate to="/auth/login" />
              </>
            }
          />
          <Route path="profile/*" element={<BaseTree />} />
          <Route
            path="profile/"
            element={
              <>
                <Navigate to="/profile/start" />
              </>
            }
          />
          <Route path="/" element={<HomeRoutes />} />
          <Route path="settings/*" element={<SettingsTree />} />
          <Route path="lets-meet/*" element={<LetsMeetRoutes />} />
          <Route path="activities/*" element={<ActivitiesRoutes />} />
          <Route path="home/*" element={<HomeRoutes />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </>
    </HelmetProvider>
  );
}

export default App;

import { Plus, Share } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Text } from 'components/text/primary/Text';
import { toast } from 'components/ui/toast/use-toast';
import { IContact } from 'types';

interface InviteListProps {
  title?: string;
  disabled?: boolean;
  eventId?: string;
  excludeOrganizer?: boolean;
  includeAll?: boolean;
  isPoll?: boolean;
  includeOnlyGoing?: boolean;
  isOrganizer?: boolean;
  contacts: IContact[];
  userPhotos?: { [key: string]: { photoURL: string; name: string } };
  onAddClick?: () => void;
  shareEvent?: () => void;
}

const HorizontalInviteList: React.FC<InviteListProps> = ({
  title,
  disabled,
  eventId,
  excludeOrganizer,
  includeAll,
  isPoll,
  includeOnlyGoing,
  isOrganizer,
  contacts,
  userPhotos,
  onAddClick,
  shareEvent,
}) => {
  const [displayContacts, setDisplayContacts] = useState<IContact[]>([]);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const isContactOrganizer = useCallback((contact: IContact): boolean => {
    return contact.organizer === 1;
  }, []);

  const getDisplayName = (contact: IContact): string => {
    if (isOrganizer && isContactOrganizer(contact)) return 'You';
    return contact.name || '';
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
    setIsDragging(true);
    setStartX(e.clientX - (listContainerRef.current?.offsetLeft ?? 0));
    setScrollLeft(listContainerRef.current?.scrollLeft ?? 0);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!isDragging || !listContainerRef.current) return;
    e.preventDefault();
    const x = e.clientX - listContainerRef.current.offsetLeft;
    const scroll = x - startX;
    listContainerRef.current.scrollLeft = scrollLeft - scroll;
  };

  const handleMouseUp = (): void => setIsDragging(false);

  useEffect(() => {
    const filteredContacts = contacts
      .filter((contact) => contact.name?.trim() !== '' && contact.status === 1)
      .sort((a, b) => {
        if (a.organizer === 1 && b.organizer !== 1) return -1;
        if (a.organizer !== 1 && b.organizer === 1) return 1;
        return 0;
      });

    setDisplayContacts(filteredContacts);
  }, [contacts]);

  const renderAvatar = (contact: IContact) => {
    const userPhoto = contact.phoneNumber
      ? userPhotos?.[contact.phoneNumber]
      : undefined;
    console.log('userPhoto', userPhoto);
    console.log('contact', contact);
    console.log('userPhotos', userPhotos);
    return (
      <div className="w-10 h-10 bg-[#32395E] rounded-full flex items-center justify-center text-lg text-white overflow-hidden">
        {userPhoto?.photoURL ? (
          <img
            src={userPhoto.photoURL}
            alt={userPhoto.name || contact.name || ''}
            className="w-full h-full object-cover"
          />
        ) : (
          <span>{contact.name?.[0] || ''}</span>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col p-4 gap-4 rounded-[12px] relative bg-[#1A1F37]">
      <style>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
        }
      `}</style>

      {(isOrganizer || !disabled) && (
        <div className="absolute right-4 top-5 flex items-center gap-2">
          <button
            onClick={async () => {
              try {
                if (shareEvent) {
                  shareEvent();
                }
              } catch (error) {
                console.error('Error in share handler:', error);
                // If you have a toast system:
                // toast({
                //   title: 'Unable to share at this time',
                //   variant: 'destructive',
                // });
              }
            }}
            className="w-6 h-6 rounded-full border border-white/20 flex items-center justify-center hover:bg-white/10 transition-colors"
          >
            <Share className="w-3 h-3 text-white" />
          </button>
          {isOrganizer && !disabled && (
            <button
              onClick={onAddClick}
              className="w-6 h-6 rounded-full border border-white/20 flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <Plus className="w-3 h-3 text-white" />
            </button>
          )}
        </div>
      )}

      <div>
        <Text
          size="small"
          className="text-[14px] font-semibold text-white font-manrope"
        >
          {title ?? 'Attendee List'}
        </Text>
        <div className="mt-[-4px]">
          <span className="text-[13px] font-normal text-white/80 font-manrope">
            {displayContacts.length} Going
          </span>
        </div>
      </div>

      <div
        ref={listContainerRef}
        className="flex gap-4 overflow-x-auto w-full hide-scrollbar"
        style={{ WebkitOverflowScrolling: 'touch' }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {displayContacts.map((contact, index) => (
          <div
            key={contact.phoneNumber || index}
            className="flex flex-col items-center justify-center min-w-[48px]"
          >
            <div className="relative flex items-center justify-center">
              {isContactOrganizer(contact) && (
                <span
                  role="img"
                  aria-label="crown"
                  className="absolute text-sm"
                  style={{ top: '-4px', left: '-4px' }}
                >
                  👑
                </span>
              )}
              {renderAvatar(contact)}
            </div>
            <span className="text-[13px] font-normal text-center mt-2 text-[rgba(255,255,255,0.8)] font-manrope">
              {getDisplayName(contact)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalInviteList;

import {
  addDays,
  differenceInDays,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
  parseISO,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  EventItem,
  EventObject,
  TimeOffRecord,
  VariantData,
} from 'components/eventCardItem/EventCardItem.types';
import { CalendarEvent } from 'modules/home/types/CalendarEvent';
import { getDayStartAndEnd } from 'modules/home/utils/helpers';
import { IAvailability } from 'modules/profile/types/types';

export const determineVariant = (variantData?: VariantData) => {
  if (!variantData) return 'draft';
  if (variantData.isPast) return 'past';
  if (variantData.isAccepted) return 'accepted';
  if (variantData.isPending) return 'maybe';
  if (variantData.isMaybe) return 'maybe';
  if (variantData.isOngoing) return 'ongoing';
  if (variantData.isUpcoming) return 'new';
  if (variantData.isPoll) return 'poll';
  if (variantData.isDraft) return 'draft';
  return 'draft';
};
import cuid from 'cuid';

export const getFilteredEventsByDate = (
  events: EventObject[],
  selectedDates?: Date[],
): EventObject[] => {
  let filteredEvents = events;
  // filter events by selected dates
  if (selectedDates && selectedDates.length > 0) {
    filteredEvents = filteredEvents.filter((event) => {
      if (event.event?.start) {
        const eventDate = parseISO(event.event.start);
        return selectedDates.some((date) => {
          return isSameDay(eventDate, date);
        });
      } else if (event.event?.end) {
        const eventDate = parseISO(event.event.end);
        return selectedDates.some((date) => {
          return isSameDay(eventDate, date);
        });
      }
      return true;
    });
  }
  return filteredEvents;
};

export const getSortedCalendarEvents = (
  events: EventObject[],
  order?: 'asc' | 'desc',
): EventObject[] => {
  // remove duplicates
  const uniqueEvents = events.reduce(
    (acc: EventObject[], event: EventObject) => {
      if (!acc.find((e) => e.event?._id === event.event?._id)) {
        acc.push(event);
      }
      return acc;
    },
    [],
  );

  // sort and return the events
  return uniqueEvents.sort((a, b) => {
    if (a.event?.start && b.event?.start) {
      const dateStrStart = a.event?.start;
      const dateStrEnd = b.event?.start;

      const date1 = parseISO(dateStrStart);
      const date2 = parseISO(dateStrEnd);

      if (order === 'asc') {
        if (isAfter(date1, date2)) {
          return 1;
        } else if (isAfter(date2, date1)) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (isAfter(date1, date2)) {
          return -1;
        } else if (isAfter(date2, date1)) {
          return 1;
        } else {
          return 0;
        }
      }
    }
    return 0;
  });
};

export const getEventsWithShiftedDates = (
  events: EventObject[],
  shift: number,
): EventObject[] => {
  return events.map((event) => {
    if (event.event?.start) {
      const date = parseISO(event.event.start);
      const shiftedDate = addDays(date, shift);
      return {
        ...event,
        event: {
          ...event.event,
          _id: `${event.event._id}1`,
          start: formatISO(shiftedDate),
        },
      };
    }
    return event;
  });
};

export const findEventOfDate = (events: EventObject[], date: Date) => {
  return events.find((event) => {
    if (event.event?.start) {
      const eventDate = parseISO(event.event.start);
      return isSameDay(eventDate, date);
    } else if (event.event?.end) {
      const eventDate = parseISO(event.event.end);
      return isSameDay(eventDate, date);
    }
    return false;
  });
};

/*
 * @param startTime should be 0 hour time slot of a day
 * @param endTime should be 0 hour time slot of a day
 * @param availabilities user availabilities
 * @returns TimeOffRecord[] time off records for the user availability
 */
export const generateTimeOffsForUserAvailability = (
  date: Date | string | undefined | null,
  availabilities: IAvailability | undefined | null,
): CalendarEvent[] => {
  if (!date || !availabilities) return [];

  const timeOffs: CalendarEvent[] = [];
  const keys = Object.keys(availabilities);
  const { endOfDay, startOfDay } = getDayStartAndEnd(
    typeof date === 'string' ? parseISO(date) : date,
  );

  const day = format(startOfDay, 'EEEE').toUpperCase();
  const availabilityEntries = availabilities[day as keyof IAvailability];

  if (!availabilityEntries) {
    // no availability found for the day - all day blocked
    const ei: Partial<EventItem> = {};
    ei.activity = '';
    timeOffs.push({
      id: cuid(),
      start: startOfDay,
      end: endOfDay,
      title: '',
      variant: 'blocked',
      eventData: ei as EventItem,
      isDeletable: false,
    });
  } else {
    if (availabilityEntries?.some((entry) => entry.allDay)) {
      // all available
      return [];
    } else {
      // different availability entries
      const sortedAvailabilityEntries = cloneDeep(availabilityEntries).map(
        (entry) => {
          return {
            ...entry,
            start: {
              dateTime:
                convertCommonTo24HoursFormat(entry.start.dateTime) ?? '00:00',
              timeZone: entry.start.timeZone,
            },
            end: entry.end
              ? {
                  dateTime:
                    convertCommonTo24HoursFormat(entry.end.dateTime) ?? '00:00',
                  timeZone: entry.end.timeZone,
                }
              : undefined,
          };
        },
      );
      sortedAvailabilityEntries?.sort((a, b) => {
        const timeA = timeToMinutes(a.start.dateTime);
        const timeB = timeToMinutes(b.start.dateTime);
        return timeA - timeB;
      });

      sortedAvailabilityEntries?.forEach((entry, index) => {
        const startTimeString = entry.start.dateTime;
        const endTimeString = entry.end?.dateTime ?? '23:59';
        const [startHours, startMinutes] = startTimeString
          .split(':')
          .map(Number);
        const [endHours, endMinutes] = endTimeString.split(':').map(Number);

        const startDate = new Date(startOfDay);
        startDate.setHours(startHours, startMinutes, 0, 0);

        // first availability entry
        if (index === 0) {
          const ei: Partial<EventItem> = {};
          ei.activity = '';
          timeOffs.push({
            id: cuid(),
            start: startOfDay,
            end: startDate,
            title: '',
            variant: 'blocked',
            eventData: ei as EventItem,
            isDeletable: false,
          });
        }

        // last availability entry
        if (index == sortedAvailabilityEntries.length - 1) {
          const endDate = new Date(endOfDay);
          endDate.setHours(endHours, endMinutes, 0, 0);
          const ei: Partial<EventItem> = {};
          ei.activity = '';
          timeOffs.push({
            id: cuid(),
            start: endDate,
            end: endOfDay,
            title: '',
            variant: 'blocked',
            eventData: ei as EventItem,
            isDeletable: false,
          });
        }

        // in between availability entries
        if (index > 0) {
          const prevAvailability = sortedAvailabilityEntries[index - 1];
          const prevEndTimeString = prevAvailability.end?.dateTime ?? '23:59';
          const [prevEndHours, prevEndMinutes] = prevEndTimeString
            .split(':')
            .map(Number);
          const prevEndDate = new Date(startOfDay);
          prevEndDate.setHours(prevEndHours, prevEndMinutes, 0, 0);
          const ei: Partial<EventItem> = {};
          ei.activity = '';
          timeOffs.push({
            id: cuid(),
            start: prevEndDate,
            end: startDate,
            title: '',
            variant: 'blocked',
            eventData: ei as EventItem,
          });
        }
      });
    }
  }

  return timeOffs;
};

// Function to convert "HH:mm" time string to minutes since midnight
const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

/*
 * generate time offs for user availability for a range of dates
 */
export const generateTimeOffsForUserAvailabilityForRange = (
  start: Date | string,
  end: Date | string,
  availabilities: IAvailability | undefined | null,
): CalendarEvent[] => {
  if (!availabilities) return [];
  const timeOffs: CalendarEvent[] = [];
  const currentDate = new Date(start);
  const endDate = new Date(end);
  while (currentDate <= endDate) {
    timeOffs.push(
      ...generateTimeOffsForUserAvailability(currentDate, availabilities),
    );
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return timeOffs;
};

export const makeBlockTimeOffsFullWidth = () => {
  const blockedElements = document.querySelectorAll('.event-card-blocked');
  blockedElements.forEach((element) => {
    const parentEvent: HTMLElement | null = element.closest('.rbc-event');
    if (parentEvent) {
      parentEvent.style.width = '100%';
      parentEvent.style.left = '0';
    }
  });
};

export const makeEventCardsFullWidth = () => {
  const eventCards = document.querySelectorAll(
    '.calendar-event-card-container',
  );
  eventCards.forEach((element) => {
    const parentEvent: HTMLElement | null = element.closest('.rbc-event');
    if (parentEvent) {
      parentEvent.style.width = '100%';
      parentEvent.style.left = '0';
    }
  });
};

/*
 * Validate if a time slot is available in user's availability
 */
export const validateTimeSlotAvailability = (
  startTime: string | Date,
  endTime: string | Date | null | undefined,
  availabilities: IAvailability | undefined | null,
): boolean => {
  if (!availabilities) return false;

  const startTimeObj =
    typeof startTime === 'string' ? new Date(startTime) : startTime;
  const endTimeObj = typeof endTime === 'string' ? new Date(endTime) : endTime;

  const startDay = format(startTimeObj, 'EEEE').toUpperCase();
  const endDay = endTimeObj
    ? format(endTimeObj, 'EEEE').toUpperCase()
    : startDay;

  // no availability found for the day - all day blocked
  const availabilityEntries = availabilities[startDay as keyof IAvailability];
  if (!availabilityEntries) return false;

  // check if day is fully available
  if (availabilityEntries.some((entry) => entry.allDay)) return true;

  // check if any matching time slot is available
  const { endOfDay, startOfDay } = getDayStartAndEnd(startTimeObj);
  const sortedAvailabilityEntries = cloneDeep(availabilityEntries).map(
    (entry) => {
      return {
        ...entry,
        start: {
          dateTime:
            convertCommonTo24HoursFormat(entry.start.dateTime) ?? '00:00',
          timeZone: entry.start.timeZone,
        },
        end: entry.end
          ? {
              dateTime:
                convertCommonTo24HoursFormat(entry.end.dateTime) ?? '00:00',
              timeZone: entry.end.timeZone,
            }
          : undefined,
      };
    },
  );
  sortedAvailabilityEntries?.sort((a, b) => {
    const timeA = timeToMinutes(a.start.dateTime);
    const timeB = timeToMinutes(b.start.dateTime);
    return timeA - timeB;
  });

  // check if any matching time slot is available
  let isAvailable = false;
  sortedAvailabilityEntries?.forEach((entry, index) => {
    const startTimeString = entry.start.dateTime;
    const [startHours, startMinutes] = startTimeString.split(':').map(Number);
    const startDate = new Date(startOfDay);
    startDate.setHours(startHours, startMinutes, 0, 0);
    if (endTimeObj) {
      const endTimeString = entry.end?.dateTime ?? '23:59';
      const [endHours, endMinutes] = endTimeString.split(':').map(Number);
      const endDate = new Date(endOfDay);
      endDate.setHours(endHours, endMinutes, 0, 0);
      isAvailable =
        startTimeObj.getTime() >= startDate.getTime() &&
        endTimeObj.getTime() <= endDate.getTime();
    } else {
      isAvailable = startTimeObj.getTime() >= startDate.getTime();
    }
  });

  return isAvailable;
};

export function convertCommonToISO(dateTimeStr?: string) {
  if (!dateTimeStr) return '';
  let parsedDate;
  parsedDate = new Date(dateTimeStr);
  if (isValid(parsedDate)) {
    return parsedDate.toISOString();
  }
  const possibleFormats = ['hh:mm a', 'HH:mm', 'yyyy-MM-dd HH:mm:ss'];
  for (const format of possibleFormats) {
    parsedDate = parse(dateTimeStr, format, new Date());
    if (isValid(parsedDate)) {
      return formatISO(parsedDate);
    }
  }
  return '';
}

export function convertCommonTo24HoursFormat(dateTime?: string) {
  const convertedToISO = convertCommonToISO(dateTime);
  return convertedToISO ? format(parseISO(convertedToISO), 'HH:mm') : undefined;
}

export function convertCommonTo12HoursFormat(dateTime?: string) {
  const convertedToISO = convertCommonToISO(dateTime);
  return convertedToISO
    ? format(parseISO(convertedToISO), 'hh:mm a')
    : undefined;
}

export function isDateTimeRangeInPast(input: string) {
  // TODO: remove invalid Z or z character from BE using regex
  input = input.replace(/[Zz]/g, '');
  // const regex = /^(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})-(\d{2}:\d{2})$/;
  const regex =
    /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)/;
  const match = input.match(regex);
  if (match) {
    const [, datePart, startTime, endTime] = match;
    const startDateTime = parse(
      `${datePart} ${startTime}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );
    const endDateTime = parse(
      `${datePart} ${endTime}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );
    const now = new Date();
    return isBefore(endDateTime, now);
  } else {
    return false;
  }
}

export const getSortedNonPastEvents = (
  events: EventObject[],
  order?: 'asc' | 'desc',
): EventObject[] => {
  const pastRemoved = getSortedCalendarEvents(events, order).filter((event) => {
    if (event.event?.type == 'poll') {
      const timeSlots = event?.event?.pollData?.checkedTimes ?? [];
      return !timeSlots.every((timeSlot) =>
        isDateTimeRangeInPast(timeSlot.time),
      );
    } else {
      if (event.event?.end) {
        return (
          new Date(event.event.end) >= new Date() &&
          new Date(event.event.start) >= new Date()
        );
      } else {
        return new Date(event.event.start) >= new Date();
      }
    }
  });
  return pastRemoved;
};

export function checkTimeOlderThan(dateTimeIsoString: string, numDays: number) {
  const givenDate = parseISO(dateTimeIsoString);
  const currentDate = new Date();
  return (
    differenceInDays(currentDate, givenDate) >= numDays &&
    isBefore(givenDate, currentDate)
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { verificationMutation } from 'modules/auth/services/verification.mutation.service';
import { userMutation } from 'modules/auth/services/user.mutation.service';
import authReducer from 'modules/auth/slices/authSlice';
import { fileMutation } from 'services/upload.service';
import { calendarApi } from 'services/calendar.service';
import { eventApi } from 'services/event.service';
import { activityApi } from 'services/activity.service';
import { publicEventApi } from 'services/public_event.service';
import { notificationMutation } from 'services/notification.service';
import { eventsApiSlice } from 'services/slices/eventsApiSlice';
import { usersApiSlice } from 'services/slices/usersApiSlice';
import { calendarApiSlice } from 'services/slices/calendarApiSlice';

export const store = configureStore({
  reducer: {
    [verificationMutation.reducerPath]: verificationMutation.reducer,
    [userMutation.reducerPath]: userMutation.reducer,
    [fileMutation.reducerPath]: fileMutation.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [publicEventApi.reducerPath]: publicEventApi.reducer,
    [notificationMutation.reducerPath]: notificationMutation.reducer,
    [eventsApiSlice.reducerPath]: eventsApiSlice.reducer,
    [usersApiSlice.reducerPath]: usersApiSlice.reducer,
    [calendarApiSlice.reducerPath]: calendarApiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      verificationMutation.middleware,
      userMutation.middleware,
      fileMutation.middleware,
      calendarApi.middleware,
      eventApi.middleware,
      activityApi.middleware,
      publicEventApi.middleware,
      notificationMutation.middleware,
      eventsApiSlice.middleware,
      usersApiSlice.middleware,
      calendarApiSlice.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

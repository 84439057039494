import { RiUser3Fill } from '@remixicon/react';
import { useEffect, useRef, useState } from 'react';
import { Text } from 'components/text/primary/Text';
import { ReactComponent as DownArrow } from 'assets/images/icons/down-arrow.svg';
import { cn } from 'utils/helpers';
import caleidoBaseApi from 'services/apiClient';

type InviteListProps = {
  list: string[];
  title?: string;
  disabled?: boolean;
  eventId?: string;
  excludeOrganizer?: boolean;
  includeAll?: boolean;
  isPoll?: boolean;
  includeOnlyGoing?: boolean;
};
const InviteList: React.FC<InviteListProps> = ({
  list: rawList,
  title,
  disabled,
  eventId,
  excludeOrganizer,
  includeAll,
  isPoll,
  includeOnlyGoing,
}) => {
  const list = rawList?.filter((c) => c.trim() != '') ?? [];

  // state to hold the list of current visible invitees
  const [_list, setList] = useState(list.slice(0, 4));

  // state to hold the list of invitees
  const [inviteeList, setInviteeList] = useState<string[]>(list ?? []);

  const ref = useRef<HTMLDivElement>(null);

  // add more invitees to the current visible list
  const addMore = () => {
    if (disabled) return;

    /*
    // add next 4
    if (_list.length <= list.length)
      setList([..._list, ...list.slice(_list.length, _list.length + 4)]);
    */
    // TODO: check the order of the list
    // add next 4
    if (inviteeList.length > _list.length) {
      setList((prev) => [
        ..._list,
        ...inviteeList.slice(_list.length, _list.length + 4),
      ]);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [_list]);

  // fetch the list of invitees every 15 seconds
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (eventId) {
      intervalRef.current = setInterval(() => {
        getEvent(eventId)
          .then((res) => {
            if (res.data?.contacts) {
              let attendingInvitees = isPoll
                ? (res.data.contacts
                    ?.filter((contact: any) => contact.organizer === 0)
                    ?.map((contact: any) => contact.name) ?? [])
                : includeAll
                  ? (res.data.contacts?.map((contact: any) => contact.name) ??
                    [])
                  : includeOnlyGoing
                    ? (res.data.contacts
                        ?.filter((contact: any) => contact?.status !== 2)
                        .map((contact: any) => contact.name) ?? [])
                    : excludeOrganizer
                      ? (res.data.contacts
                          ?.filter(
                            (contact: any) =>
                              contact?.status === 1 && contact?.organizer === 0,
                          )
                          .map((contact: any) => contact.name) ?? [])
                      : (res.data.contacts
                          ?.filter((contact: any) => contact?.status === 1)
                          .map((contact: any) => contact.name) ?? []);

              attendingInvitees = attendingInvitees.filter(
                (c: string) => c.trim() != '',
              );
              setInviteeList((prev) => attendingInvitees);
              setList((prev) => [...attendingInvitees.slice(0, _list.length)]);
            }
          })
          .catch((err) => {});
      }, 15000);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [eventId, _list]);

  // get public view of the event from API
  const getEvent = async (eventId: string) => {
    return caleidoBaseApi.get(`/event/share/${eventId}`);
  };

  const anyInvites = list.length > 0;
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        {anyInvites && (
          <>
            <Text size="small" className="text-base font-medium">
              {title ?? 'Inviting'}
            </Text>

            <div className="flex items-center gap-1 px-2.5 flex-row text-violet-700 bg-white rounded-xl">
              <RiUser3Fill size={12} />

              <Text
                size="small"
                className="text-violet-700 text-xs leading-none"
              >
                {inviteeList.length}
              </Text>
            </div>
          </>
        )}
      </div>

      <div className="transition-all overflow-hidden duration-200" ref={ref}>
        <div className="grid grid-cols-2 gap-2">
          {_list.map((name: string, index) => (
            <div
              key={index}
              className="bg-purple-200 text-slate-900 text-sm font-normal px-2 py-1 rounded-full text-center whitespace-nowrap text-ellipsis overflow-hidden"
            >
              {name}
            </div>
          ))}
        </div>
      </div>
      {_list.length < inviteeList.length && (
        <div className="flex justify-center">
          <div
            className={cn(
              'text-sm flex flex-col items-center gap-0.5 opacity-50 cursor-pointer select-none',
              disabled && 'cursor-default',
            )}
            onClick={addMore}
          >
            View more <DownArrow />
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteList;

type FormValues = {
  name: string;
  email: string;
  message: string;
};

type FormErrors = { [key in keyof FormValues]?: string };

export const validateForm = (formValues: FormValues): FormErrors => {
  const newErrors: FormErrors = {};

  if (!formValues.name.trim()) {
    newErrors.name = 'Full name is required.';
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!formValues.email) {
    newErrors.email = 'Email is required';
  } else if (!regex.test(formValues.email)) {
    newErrors.email = 'Invalid Email';
  }
  if (!formValues.email.trim().includes('@')) {
    newErrors.email = 'Invalid email address.';
  }
  if (!formValues.message.trim()) {
    newErrors.message = 'Message is required.';
  }

  return newErrors;
};

import { useState, useEffect } from 'react';

const useSamsungS23Detection = () => {
  const [isSamsungS23, setIsSamsungS23] = useState(false);

  useEffect(() => {
    // Check if the user agent indicates a Samsung device
    const userAgent = navigator.userAgent || navigator.vendor;
    const isSamsungDevice = /Samsung|SM-G99/.test(userAgent); // Updated to cover Samsung devices

    // Check if the device resolution matches that of the Galaxy S23 (1080x2340)
    const isGalaxyS23Resolution =
      window.screen.width === 1080 && window.screen.height === 2340;

    // Calculate the aspect ratio
    const aspectRatio = window.screen.width / window.screen.height;

    // Target aspect ratio of 6:13
    const targetAspectRatio = 6 / 13;

    // Check if the aspect ratio matches
    const isTargetAspectRatio =
      Math.abs(aspectRatio - targetAspectRatio) < 0.01; // Allow for a small margin of error

    // Set state if it's a Samsung device and either condition is true
    setIsSamsungS23(
      isSamsungDevice && (isGalaxyS23Resolution || isTargetAspectRatio),
    );
  }, []);

  return { isSamsungS23 };
};

export default useSamsungS23Detection;

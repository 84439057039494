import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AccessAndRefreshTokens, IUser } from 'modules/auth/types/types';

const BASE_URL = process.env.REACT_APP_BASE_URL;

type SendOtpParams = {
  phoneNumber: string;
  smsNotifications?: boolean;
};

type VerifyOtpParams = {
  to: string;
  otp: string;
};

type SendOtpResponse = {
  isSuccess: boolean;
};

type VerifyOtpResponse = {
  user: IUser;
  tokens: AccessAndRefreshTokens;
};

export const verificationMutation = createApi({
  reducerPath: 'verificationMutation',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    sendOtp: builder.mutation<SendOtpResponse, SendOtpParams>({
      query: (body) => ({
        url: '/auth/send-otp',
        method: 'POST',
        body,
      }),
    }),
    verifyOtp: builder.mutation<VerifyOtpResponse, VerifyOtpParams>({
      query: (body) => ({
        url: '/auth/verify-otp',
        method: 'POST',
        body,
      }),
    }),
    whitelistUsers: builder.mutation<VerifyOtpResponse, SendOtpParams>({
      query: (body) => ({
        url: '/auth/whitelist-login',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSendOtpMutation,
  useVerifyOtpMutation,
  useWhitelistUsersMutation,
} = verificationMutation;

import dayjs from 'dayjs';
import { some } from 'lodash';
import { IAvailabilityPeriod } from 'modules/profile/types/types';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';

export const normalizeSlots = (
  slots: IAvailabilityPeriod[],
): IAvailabilityPeriod[] => {
  if (!Array.isArray(slots) || slots.length === 0) return [];

  const sortedSlots = slots.sort((a, b) =>
    dayjs(a.start.dateTime).isBefore(dayjs(b.start.dateTime)) ? -1 : 1,
  );

  const normalizedSlots: IAvailabilityPeriod[] = [];
  let currentSlot = { ...sortedSlots[0] };

  // fix the issue with end time format
  if (currentSlot.end && currentSlot.end.dateTime) {
    currentSlot.end.dateTime = new Date(currentSlot.end.dateTime).toISOString();
  }

  for (let i = 1; i < sortedSlots.length; i++) {
    const nextSlot = sortedSlots[i];
    if (nextSlot.end === undefined || currentSlot.end === undefined) {
      normalizedSlots.push(currentSlot);
      continue;
    }
    if (
      dayjs(nextSlot.start.dateTime).isBefore(
        dayjs(currentSlot.end.dateTime).add(1, 'minute'),
      )
    ) {
      currentSlot.end.dateTime = dayjs(nextSlot.end.dateTime).isAfter(
        dayjs(currentSlot.end.dateTime),
      )
        ? nextSlot.end.dateTime
        : currentSlot.end.dateTime;
    } else {
      normalizedSlots.push(currentSlot);
      currentSlot = { ...nextSlot };
    }
  }

  normalizedSlots.push(currentSlot);

  // revert due to BE conflict
  /*
  // format to exclude data part from time string
  normalizedSlots.forEach((slot) => {
    slot.start.dateTime = dayjs(slot.start.dateTime).format('HH:mm');
    if (slot.end) {
      slot.end.dateTime = dayjs(slot.end.dateTime).format('HH:mm');
    }
  });
  */

  return normalizedSlots;
};

export const areTimeSlotsOverlapping = (
  { start: startA, end: endA }: IAvailabilityPeriod,
  { start: startB, end: endB }: IAvailabilityPeriod,
): boolean => {
  const today = dayjs().format('YYYY-MM-DD');

  if (endA === undefined || endB === undefined) return false;

  const formatTime = (dateTime: string) => dayjs(dateTime).format('HH:mm');

  const startTimeA = dayjs(`${today}T${formatTime(startA.dateTime)}`);
  const endTimeA = dayjs(`${today}T${formatTime(endA.dateTime)}`);
  const startTimeB = dayjs(`${today}T${formatTime(startB.dateTime)}`);
  const endTimeB = dayjs(`${today}T${formatTime(endB.dateTime)}`);

  return startTimeA.isBefore(endTimeB) && startTimeB.isBefore(endTimeA);
};

export const isValidTimeSlot = (
  start?: ITimeSlot,
  end?: ITimeSlot,
): boolean => {
  if (!end) return true;
  if (!start?.dateTime) return true;

  const today = dayjs().format('YYYY-MM-DD');

  const startTime = `${today}T${dayjs(start.dateTime).format('HH:mm')}`;
  const endTime = `${today}T${dayjs(end.dateTime).format('HH:mm')}`;

  const startDateTime = dayjs.tz(startTime, start.timeZone);
  const endDateTime = dayjs.tz(endTime, end.timeZone);

  if (!startDateTime.isValid() || !endDateTime.isValid()) {
    return false;
  }

  return startDateTime.isBefore(endDateTime);
};

export const validateAvailabilityPeriods = (
  availabilityPeriods: IAvailabilityPeriod[],
): boolean => {
  if (availabilityPeriods.length === 1) return false;
  return some(availabilityPeriods, (periodA, index) =>
    some(availabilityPeriods.slice(index + 1), (periodB) =>
      areTimeSlotsOverlapping(periodA, periodB),
    ),
  );
};

export const sortAvailabilityPeriods = (
  availabilityPeriods: IAvailabilityPeriod[],
): IAvailabilityPeriod[] => {
  return availabilityPeriods.sort((a, b) =>
    dayjs(a.start.dateTime).isBefore(dayjs(b.start.dateTime)) ? -1 : 1,
  );
};

export const isTimeAvailableOnDate = (
  slots: IAvailabilityPeriod[],
  date: string = dayjs().format('YYYY-MM-DD'),
): boolean => {
  if (slots.length === 0) return true;

  if (slots.some((slot) => slot.allDay || slot.end === undefined)) return true;

  const dateStart = dayjs(date).startOf('day');
  const dateEnd = dayjs(date).endOf('day');

  const requiredSlotDuration = dayjs.duration(30, 'minutes');

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const slotStart = dayjs.tz(
        `${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`,
        slots[0]?.start.timeZone || 'UTC',
      );
      const slotEnd = slotStart.add(requiredSlotDuration);

      if (slotEnd.isAfter(dateEnd)) break;

      const isAvailable = !slots.some((period) => {
        if (!period.end) return false;
        const periodStart = dayjs.tz(
          period.start.dateTime,
          period.start.timeZone,
        );
        const periodEnd = dayjs.tz(period.end.dateTime, period.end.timeZone);
        return slotStart.isBefore(periodEnd) && slotEnd.isAfter(periodStart);
      });

      if (isAvailable) {
        return true;
      }
    }
  }

  return false;
};

export function isTimeSlotAvailable(
  slots: IAvailabilityPeriod[],
  timeZone: string = new Intl.DateTimeFormat().resolvedOptions().timeZone,
  minSlotDuration: number = 30,
): boolean {
  const today = dayjs().format('YYYY-MM-DD');
  const maxSlotDuration = dayjs.duration(2, 'hours');

  let potentialStart = dayjs.tz(`${today}T00:00:00`, timeZone);
  let potentialEnd = potentialStart.add(maxSlotDuration);

  while (true) {
    const slotOverlap = slots.some((slot) => {
      if (!slot.end) return false;
      const slotStart = dayjs.tz(slot.start.dateTime, slot.start.timeZone);
      const slotEnd = dayjs.tz(slot.end.dateTime, slot.end.timeZone);
      return (
        potentialStart.isBefore(slotEnd) && potentialEnd.isAfter(slotStart)
      );
    });

    if (!slotOverlap) {
      const endOfDay = dayjs.tz(`${today}T23:59:59`, timeZone);
      const durationLeft = endOfDay.diff(potentialStart);

      if (
        durationLeft >=
        dayjs.duration(minSlotDuration, 'minutes').asMilliseconds()
      ) {
        return true;
      }
    }

    potentialStart = potentialEnd;
    potentialEnd = potentialStart.add(maxSlotDuration);

    if (potentialStart.isAfter(dayjs.tz(`${today}T23:59:59`, timeZone))) {
      return false;
    }
  }
}

export const getGeneralTimeFormat = (time: string): string => {
  // if format is in 2024-09-12T03:58:27.476+00:00 format return the same. If in 12 hours format 03:58 format with todays date
  if (time.includes('T')) {
    return time;
  }
  return dayjs(
    `${dayjs().format('YYYY-MM-DD')} ${time}`,
    'YYYY-MM-DD HH:mm',
  ).toISOString();
};

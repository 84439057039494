import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { ReactComponent as MenuIcon } from 'assets/images/common/menu.svg';
import SideBarButton from 'components/sidebar/sidebarButton';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { cn } from 'utils/helpers';
import OverlayLoader from 'components/loader/OverlayLoader';

const ViewListHeader = (props: { sticky?: boolean }) => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (user) {
      setIsUserLoaded(true);
    }
  }, [user]);

  if (!isUserLoaded) {
    return <OverlayLoader />;
  }

  return (
    <>
      <div
        className={cn(
          'flex pt-3 pb-4 min-h-[76px]',
          props.sticky && 'sticky top-0 pt-4 pb-5 bg-[#121538] z-10',
        )}
      >
        <Link to="/settings">
          <div className="w-[40px] h-[40px] bg-purple-100 rounded-full flex items-center justify-center">
            <ProfileAvatar size={45} url={user?.photoURL} />
          </div>
        </Link>
        <div className="text-sm flex items-center pl-3 font-sofia-normal leading-normal">
          @{user?.username}
        </div>
        <div className="flex items-center justify-end ml-auto">
          <SideBarButton />
        </div>
      </div>

      <div
        className={
          'mb-4 font-sofia-normal text-[20px] tracking-tighter leading-[30px]'
        }
      >
        <h1>Welcome {user?.name?.split(' ')[0]}! Any plans today?</h1>
      </div>
    </>
  );
};

export default ViewListHeader;

import { useEffect, useState, useMemo } from 'react';
import { isArray, isEmpty, isString, debounce } from 'lodash';
import { Plus } from 'lucide-react';
import { IUser } from 'modules/auth/types/types';
import { Text } from 'components/text/primary/Text';
import { Contact } from 'modules/profile/types/types';

import ContactActions from 'modules/settings/enums/enum';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import AddContact from 'modules/settings/components/contactActions/AddContact';
import ImportContacts from 'modules/settings/components/contactActions/ImportContacts';
import TextField from 'components/textfield/TextField';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import ContactsLayout from 'modules/settings/layouts/ContactsLayout';
import { ContactActionProps } from 'modules/settings/types/types';
import { getFromNowTime } from 'utils/dateTimeUtils';
import { ReactComponent as Logo } from 'assets/images/profile/caleido-logo.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUser } from 'modules/auth/slices/authSlice';
import { deleteContactFromUser } from 'utils/helpers';

type ContactsProps = {
  setPrevOrSkip: () => void;
  update?: any;
  data?: IUser | undefined;
  title?: string;
  loading?: boolean;
  error?: boolean;
};

const ContactActionComponents: {
  [key in ContactActions]: React.FC<ContactActionProps>;
} = {
  [ContactActions.IMPORT_CONTACTS]: ImportContacts,
  [ContactActions.ADD_CONTACTS]: AddContact,
  [ContactActions.EDIT_CONTACTS]: AddContact,
  [ContactActions.VIEW_CONTACTS]: AddContact,
};

const ContactItem = ({
  name,
  photoURL,
  isUser,
  onClick,
}: {
  name: string;
  photoURL: string | null | undefined;
  isUser: boolean;

  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => (
  <div
    onClick={onClick}
    className="flex items-center gap-4 px-4 py-2 bg-[#32395E] rounded-lg cursor-pointer"
  >
    <ProfileAvatar size={40} url={photoURL ?? ''} hideBorder />
    <Text
      size="small"
      className="text-xs font-semibold text-center truncate contact-search-name"
    >
      {name}
    </Text>
    {isUser && <div className="ml-auto">{<Logo height={'26px'} />}</div>}
  </div>
);

const Contacts = ({ update, data, title, loading, error }: ContactsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentContact, setCurrentContact] = useState<Contact | undefined>(
    undefined,
  );
  const [importedContacts, setImportedContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [visible, setVisible] = useState(false);
  const [contactAction, setContactAction] = useState<ContactActions>(
    ContactActions.IMPORT_CONTACTS,
  );
  const dispatch = useAppDispatch();

  const ActionComponent = ContactActionComponents[contactAction];

  const sanitizePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, '');
  };

  const onSubmitForm = async (contacts: Contact[]) => {
    try {
      const updateData = {
        contacts: contacts,
      };
      const response = await update?.({ data: updateData }).unwrap();
      if (response) {
        dispatch(setUser(response));
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDrawer = (action: ContactActions, value?: Contact) => {
    setVisible(false);
    if (value) {
      const viewContact = {
        ...value,
        phoneNumber: sanitizePhoneNumber(value.phoneNumber),
      };
      setCurrentContact(viewContact);
    }
    setVisible(true);
    setContactAction(action);
  };

  const handleClose = () => {
    setVisible(false);
    setCurrentContact(undefined);
  };

  const actionProps: ContactActionProps = {
    initialContacts: importedContacts,
    handleContactsChange: onSubmitForm,
    setContactAction: setContactAction,
    loading: loading!,
    error: error!,
    contact: currentContact,
    isEdit: contactAction === ContactActions.EDIT_CONTACTS,
    isView: contactAction === ContactActions.VIEW_CONTACTS,
    isAdd: contactAction === ContactActions.ADD_CONTACTS,
    deleteContact: async (phoneNumber: string) => {
      // TODO: this + should be handled in the backend or something cleaner....
      await deleteContactFromUser('+' + phoneNumber);

      // TODO: Handle this better than just page reload ...
      window.location.reload();
    },
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = importedContacts?.filter((contact) =>
          contact.name.toLowerCase()?.includes(lowerCaseQuery),
        );
        setFilteredContacts(filtered);
      }, 300),
    [importedContacts],
  );

  useEffect(() => {
    const existingContacts = data?.contacts as any;

    if (isString(existingContacts)) {
      setImportedContacts(JSON.parse(existingContacts) ?? []);
    } else if (isArray(existingContacts)) setImportedContacts(existingContacts);
  }, [data?.contacts]);

  useEffect(() => {
    if (!visible) setCurrentContact(undefined);
  }, [visible]);

  useEffect(() => {
    setFilteredContacts(importedContacts);
  }, [importedContacts]);

  return (
    <div className="flex flex-col items-center w-full gap-2 mb-12">
      <div className="flex flex-col w-full gap-1">
        <Text size="large">{title ?? 'My contacts'}</Text>
        {data?.updatedContactsAt && (
          <Text size="small" className="mt-2 text-slate-400">
            Last updated {getFromNowTime(data?.updatedContactsAt)}
          </Text>
        )}

        <Text
          size="small"
          onClick={() => handleOpenDrawer(ContactActions.IMPORT_CONTACTS)}
          className="underline cursor-pointer text-slate-400 decoration-solid"
        >
          Import Contacts
        </Text>

        <div className="flex flex-col w-full gap-3">
          <TextField
            placeholder="Search contacts"
            value={searchTerm}
            onChange={handleSearchChange}
            className="mt-6 mb-4 mr-4"
          />
          <ContactsLayout
            isSearching={!isEmpty(searchTerm)}
            isDrawerOpen={visible}
          >
            {filteredContacts?.map((contact: Contact, index) => (
              <ContactItem
                key={index}
                onClick={() =>
                  handleOpenDrawer(ContactActions.VIEW_CONTACTS, contact)
                }
                name={contact?.name}
                isUser={Boolean(contact.userId)}
                photoURL={
                  contact.userId
                    ? `${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`
                    : undefined
                }
              />
            ))}
          </ContactsLayout>
        </div>
        <button
          onClick={() => handleOpenDrawer(ContactActions.ADD_CONTACTS)}
          className="fixed p-4 text-white rounded-full shadow-lg bg-secondary bottom-6 right-6"
        >
          <Plus color="#FFF" />
        </button>
        <Drawer
          setVisible={setVisible}
          visible={visible}
          className="w-full mx-auto border border-none outline-none max-w-none"
        >
          <ActionComponent {...actionProps} />
        </Drawer>
      </div>
    </div>
  );
};

export default Contacts;

type LayoutProps = {
  children: React.ReactNode;
};

const ReceiveLayout: React.FC<LayoutProps> = (props) => {
  return (
    <div className="flex items-center justify-center text-white bg-main min-h-screen relative">
      {/* Background gradients */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute top-0 left-1/2">
          <div className="absolute w-[276px] h-[276px] rounded-[276px] opacity-70 bg-[linear-gradient(166deg,#03040E_3.53%,#07031C_19.26%,#0E0335_33.75%,#170259_43.42%,#260392_57.23%,#401DBD_73.29%,#7876E6_81.45%)] blur-[100px] -translate-x-[200px]" />
          <div className="absolute w-[366px] h-[366px] rounded-[366px] opacity-70 bg-[linear-gradient(166deg,#03040E_3.53%,#07031C_19.26%,#0E0335_33.75%,#170259_43.42%,#260392_57.23%,#401DBD_73.29%,#7876E6_81.45%)] blur-[100px] -mt-20 -translate-x-[200px]" />
        </div>
      </div>

      <div className="flex flex-col min-h-screen w-full max-w-md overflow-y-auto relative z-10">
        <div className="flex flex-col min-h-screen flex-1 w-full">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ReceiveLayout;

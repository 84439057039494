import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useForm, Controller, Resolver } from 'react-hook-form';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import OneSignal from 'react-onesignal';
import { AuthType } from 'types/common';
import { useAuthContext } from 'modules/auth/context/AuthContext';
import {
  useSendOtpMutation,
  useWhitelistUsersMutation,
} from 'modules/auth/services/verification.mutation.service';
import MainButton from 'modules/auth/components/MainButton';
import CountryCodeDropdown from 'modules/auth/components/CountryCodeDropDown';
import countryCodes from 'data/country-codes.json';
import { cn } from 'utils/helpers';
import {
  INVALID_LK_NUMBER,
  INVALID_PHONE_NUMBER,
  authPagesTextMap,
} from 'modules/auth/constants/constants';
import { SecondaryText } from 'components/text/secondary/Text';
import AuthLayout from 'modules/auth/layouts/AuthLayout';
import { Text } from 'components/text/primary/Text';
import LinkButton from 'components/buttons/main/LinkButton';
import CheckboxAsRadioButton from 'modules/auth/components/CheckBoxAsRadioButton';

import { getPrefixByCountryCode } from 'modules/auth/utils/stringUtils';
import { login } from 'modules/auth/slices/authSlice';
import { IUser } from 'modules/auth/types/types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useKeyboardHeightReset from 'hooks/useKeyboardHeightReset';
import { ReactComponent as YesIcon } from 'assets/images/activity/yes.svg';
import { ReactComponent as NoIcon } from 'assets/images/activity/no.svg';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import ResponseButton from 'modules/letsMeet/components/meetResponse/responseButton';
import useSamsungS23Detection from 'hooks/isLatestSamsung';

import useLocalStorage from 'hooks/useLocalStorage';
import styles from './create.module.css';

type FormData = {
  phoneNumber: { code: string; number: string };
  termsAccepted: boolean;
};

type ComponentProps = {
  type: AuthType;
};

const createResolver = (type: AuthType): Resolver<FormData> => {
  return async (values) => {
    const errors: any = {};
    const validatedValues = { ...values };

    if (!values.phoneNumber?.number) {
      errors.phoneNumber = errors.phoneNumber || {};
      errors.phoneNumber.number = {
        type: 'required',
        message: 'Phone number is required.',
      };
    } else if (
      !parsePhoneNumber(
        values.phoneNumber.number,
        getPrefixByCountryCode(values.phoneNumber.code) as CountryCode,
      )?.isValid()
    ) {
      errors.phoneNumber = errors.phoneNumber || {};
      errors.phoneNumber.number = {
        type: 'pattern',
        message: 'Invalid phone number.',
      };
    }

    if (type === AuthType.SIGN_UP && !values.termsAccepted) {
      errors.termsAccepted = {
        type: 'required',
        message: 'You must accept the terms and conditions.',
      };
    }

    return {
      values: Object.keys(errors).length ? {} : validatedValues,
      errors,
    };
  };
};

const SignUp = ({ type }: ComponentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { state, setAuthState } = useAuthContext();
  const resolver = useMemo(() => createResolver(type), [type]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormData>({ resolver });

  const { isKeyboardOpen } = useKeyboardHeightReset();
  const { isSamsungS23 } = useSamsungS23Detection();

  const [
    whitelistUsers,
    { isLoading: isWhitelistUsersVerifying, isError: isWhitelistUsersError },
  ] = useWhitelistUsersMutation();
  const [sendOtp, { isLoading: isSending, isError }] = useSendOtpMutation();
  const [otpError, setOTPError] = useState<boolean>(false);

  const isSignIn = type === AuthType.SIGN_IN;

  const sanitizePhoneNumber = (number: string) => number.replace(/\D/g, '');

  const [smsAccepted, setSmsAccepted] = useState<boolean>(false);
  const [conformDialogVisible, setConformDialogVisible] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<FormData | null>(null);

  const [smsNotificationsAccepted, setSmsNotificationsAccepted] =
    useLocalStorage('cal-user-sms-notifications', false);

  const onSubmit = async (data: FormData) => {
    // show prompt if sms is not accepted
    const promptSmsStatus = smsAccepted ? true : (data as any).promptSmsStatus;
    if (!isSignIn && promptSmsStatus === undefined) {
      setFormData(data);
      setConformDialogVisible(true);
      return;
    }

    setSmsNotificationsAccepted(promptSmsStatus);

    if (!isEmpty(state.phoneNumber.to) && !isEmpty(state.termsAccepted)) {
      if (type === AuthType.SIGN_UP) navigate('/auth/verify');
      else navigate('/auth/login-verify');
    }
    const { code = '+1', number } = data.phoneNumber;
    const num = parsePhoneNumber(
      number,
      getPrefixByCountryCode(code) as CountryCode,
    );
    const formattedPhoneNumber = num?.number;

    if (!formattedPhoneNumber) return;

    setAuthState({
      phoneNumber: {
        number: num.nationalNumber.split(' ').join(''),
        code,
        to: formattedPhoneNumber,
      },
      formattedPhoneNumber: formattedPhoneNumber,
      termsAccepted: data.termsAccepted,
    });

    // List of numbers to bypass OTP (defined in environment variables)
    const bypassNumbers = process.env.REACT_APP_BYPASS_NUMBERS
      ? JSON.parse(process.env.REACT_APP_BYPASS_NUMBERS)
      : [];

    const isDevOrStaging =
      process.env.REACT_APP_CHANNEL === 'develop' ||
      process.env.REACT_APP_CHANNEL === 'staging' ||
      process.env.REACT_APP_CHANNEL === 'local' ||
      process.env.REACT_APP_CHANNEL === 'production';
    const isBypassNumber = bypassNumbers.includes(formattedPhoneNumber);

    // If in dev or staging, and the phone number is in the bypass list, bypass the OTP logic
    if (isDevOrStaging && isBypassNumber) {
      const response = await whitelistUsers({
        phoneNumber: formattedPhoneNumber,
      }).unwrap();
      if (response) {
        dispatch(login(response));
        const user: IUser = response.user;

        if (isEmpty(user.name) || isEmpty(user.username)) {
          navigate('/profile/onboarding');
          reset();
          return;
        } else {
          navigate('/home');
        }

        if (user && user.id) {
          // OneSignal - Allow Request Prompt
          OneSignal.logout();
          await OneSignal.Notifications.requestPermission();

          // OneSignal - Passing the User Details for OneSignal
          OneSignal.login(user?.id);
          OneSignal.User.addSms(user?.phoneNumber);
        }
      }
    } else {
      if (isDevOrStaging && formattedPhoneNumber.startsWith('+94')) {
        setOTPError(true);
        return;
      } else {
        // Regular flow for environments or numbers that don't bypass OTP
        try {
          const response = await sendOtp({
            phoneNumber: formattedPhoneNumber,
          }).unwrap();
          if (response) {
            if (type === AuthType.SIGN_UP) navigate('/auth/verify');
            else navigate('/auth/login-verify');
          }
        } catch (err) {
          console.log(err);
        } finally {
          reset();
        }
      }
    }
  };

  useEffect(() => {
    if (state) {
      reset({
        phoneNumber: {
          number: state.phoneNumber.number,
          code: state.phoneNumber.code,
        },
        termsAccepted: state.termsAccepted ?? false,
      });
    }
  }, [state, reset]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setValue('phoneNumber', {
      code: countryCodes.some(
        (country) => country.code === queryParams.get('code'),
      )
        ? (queryParams.get('code') ?? '+1')
        : '+1',

      number: queryParams.get('phone') ?? '',
    });
  }, []);

  const currentText = authPagesTextMap[type];

  return (
    <AuthLayout className={cn('justify-between', styles.background)}>
      <div className="w-full max-w-xs mt-4 min-w-64">
        <ChevronLeft className="cursor-pointer" onClick={() => navigate(-1)} />
      </div>
      <div className="w-full max-w-xs my-8 mb-6 min-w-64">
        <div className="flex flex-col gap-y-2">
          <SecondaryText className="mb-4" size="small">
            CALEIDO
          </SecondaryText>

          <Text size="large">{currentText.title}</Text>

          <div className="inline-flex items-baseline w-full">
            <Text size="extra-small" className="text-sm">
              {currentText.subTitle}
            </Text>
            <LinkButton to={isSignIn ? '/auth/create' : '/auth/login'}>
              <Text
                size="extra-small"
                className="ml-1 font-semibold cursor-pointer text-sm"
              >
                {currentText.linkText}
              </Text>
            </LinkButton>
          </div>
          <div
            className={`flex flex-col ${isSignIn ? 'mt-4 mb-[4.5rem]' : 'my-4'}`}
          >
            <Text size="small" className="font-sofia-extralight">
              {currentText.infoText}
            </Text>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <CountryCodeDropdown
                  selectedCode={field.value?.code ?? '+1'}
                  phoneNumber={field.value?.number ?? ''}
                  placeHolder={
                    field.value?.code == '+1'
                      ? '(000) 000-0000'
                      : '000 000 0000'
                  }
                  inputClassName=""
                  triggerClassName=""
                  onCodeSelect={(code) =>
                    setValue('phoneNumber', {
                      number: control._formValues?.phoneNumber?.number ?? '',
                      code,
                    })
                  }
                  onPhoneNumberChange={(number) =>
                    setValue('phoneNumber', {
                      number,
                      code: control._formValues?.phoneNumber?.code,
                    })
                  }
                  className="my-4 p-1 text-base leading-4"
                  countryCodes={countryCodes}
                />
              )}
            />
            {errors.phoneNumber?.number && (
              <Text size="extra-small" type="error">
                {errors.phoneNumber.number.message}
              </Text>
            )}
            {isError ||
              (isWhitelistUsersError && (
                <Text size="extra-small" type="error">
                  {INVALID_PHONE_NUMBER}
                </Text>
              ))}
            {otpError && (
              <Text size="extra-small" type="error">
                {INVALID_LK_NUMBER}
              </Text>
            )}
          </div>
          {!isSignIn && (
            <>
              <div className="flex flex-col w-full gap-2 mb-2">
                <div className="inline-flex items-center w-full ml-0.5">
                  <Controller
                    name="termsAccepted"
                    control={control}
                    render={({ field }) => (
                      <CheckboxAsRadioButton
                        className="mr-2"
                        onChange={field.onChange}
                        checked={field.value}
                      />
                    )}
                  />
                  <Text size="extra-small" className="text-sm">
                    I agree to the{' '}
                  </Text>
                  <LinkButton className="ml-1" to="/terms">
                    <Text
                      size="extra-small"
                      className="font-semibold cursor-pointer text-sm"
                    >
                      Terms and Conditions
                    </Text>
                  </LinkButton>
                </div>
                {errors.termsAccepted && (
                  <Text size="extra-small" type="error">
                    {errors.termsAccepted.message}
                  </Text>
                )}
              </div>
              {/* TODO: the way this functionality built is not proper. using a separate component outside the original flow. */}
              <div className="flex flex-col w-full gap-2 mb-4">
                <div className="inline-flex items-center w-full ml-0.5">
                  <CheckboxAsRadioButton
                    className="mr-2"
                    onChange={(e) => setSmsAccepted(e.target.checked)}
                    checked={smsAccepted}
                  />
                  <Text size="extra-small" className="text-xs">
                    I agree to receive event notification SMS messages from
                    Caleido. Message frequency may vary. Reply STOP to
                    unsubscribe or use app settings to opt-out.
                  </Text>
                </div>
              </div>
            </>
          )}
          <MainButton
            type="primary"
            icon={<ChevronRight color="#fff" />}
            onClick={handleSubmit(onSubmit)}
            loading={isSending}
          >
            <Text size="small" className="text-base font-sofia-medium">
              {currentText.buttonText}
            </Text>
          </MainButton>
        </div>
      </div>
      <div
        className={`flex w-full justify-center space-x-2 ${isSamsungS23 ? 'mb-6' : ''}`}
      >
        <Link to="/terms" className="text-white text-sm">
          Terms
        </Link>
        <span className="text-white text-sm">|</span>
        <Link to="/privacy" className="text-white text-sm">
          Privacy
        </Link>
      </div>
      <AlertDialog open={conformDialogVisible}>
        <AlertDialogContent className="bg-slate-900 py-16 outline-none border-none">
          <AlertDialogHeader>
            <AlertDialogTitle></AlertDialogTitle>
            <AlertDialogDescription className="text-white">
              Continue without SMS notifications?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="">
            <div className="flex justify-center space-x-0">
              <ResponseButton
                selected={false}
                onClick={() => {
                  setConformDialogVisible(false);
                  onSubmit({ ...formData, promptSmsStatus: false } as any);
                }}
                title="Yes"
                icon={<YesIcon />}
                className="w-32 py-2 mx-2"
              />
              <ResponseButton
                selected={false}
                onClick={() => {
                  setConformDialogVisible(false);
                }}
                title="No"
                icon={<NoIcon />}
                className="w-32 py-2"
              />
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AuthLayout>
  );
};

export default SignUp;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { AlertCircleIcon, Check, CheckIcon, Trash2Icon } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import {
  useGoogleCalEventsMutation,
  useGoogleCalTokenCheckQuery,
  useUnsyncGoogleCalenderMutation,
} from 'services/calendar.service';
import MainButton from 'modules/auth/components/MainButton';
import { toast } from 'components/ui/toast/use-toast';

type SyncCalenderProps = {
  setPrevOrSkip: () => void;
};

const SyncCalenderButton = forwardRef<(() => void) | null, SyncCalenderProps>(
  ({ setPrevOrSkip }, ref) => {
    useImperativeHandle(ref, () => setPrevOrSkip);

    // const {data: authUrl, error: authUrlError, isLoading: authUrlIsLoading} = useGoogleCalAuthQuery();
    const authUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;

    const [connecting, setConnecting] = useState(false);

    const {
      data: token,
      refetch: refetchToken,
      isFetching,
      isLoading,
      status,
    } = useGoogleCalTokenCheckQuery();
    const [unsyncGoogleCalender, { isLoading: isUnSyncGCalLoading, data }] =
      useUnsyncGoogleCalenderMutation();

    useEffect(() => {
      if (connecting) {
        7;
        if (status === 'fulfilled') {
          setConnecting(false);
        }
      }
    }, [token, status]);

    const handleSignInClick = async () => {
      // popup auth window
      if (!authUrl) {
        return;
      }

      const authWindow = window.open(authUrl, '_blank', 'width=500,height=800');

      // check if the window is closed
      const interval = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(interval);
          setConnecting(true);
          refetchToken();
        }
      }, 1000);
    };

    const onGoogleCalenderUnSync = async (event: {
      stopPropagation: () => void;
    }) => {
      event.stopPropagation();
      try {
        const response = await unsyncGoogleCalender?.({}).unwrap();

        if (response) {
          toast({
            title: '',
            description: <div>{response?.message}</div>,
            fullscreen: true,
            backgroundColor: '#E0F4DB',
            textColor: '#060606',
            icon: <CheckIcon />,
          });
          refetchToken();
        }
      } catch (error) {
        toast({
          title: '',
          description: (
            <div>Failed to unsync Google Calendar. Please try again later.</div>
          ),
          fullscreen: true,
          backgroundColor: '#FDE8E8',
          textColor: '#D32F2F',
          icon: <AlertCircleIcon />,
        });
      }
    };

    return (
      <div className="flex flex-col items-center w-full gap-2">
        <div className="flex flex-col w-full gap-1">
          {!token || token?.token ? (
            <div
              onClick={handleSignInClick}
              className="w-full px-4 py-4 mt-8 bg-white border-0 rounded-2xl bg-opacity-10"
            >
              <div>
                <img
                  src="/google_icon.png"
                  alt="Google Icon"
                  width={30}
                  height={30}
                  className={'inline mr-1'}
                />
                <Text size="small" className={'inline'}>
                  Google Calendar
                </Text>
              </div>

              {connecting ? (
                <>
                  <div className={'flex justify-between items-center'}>
                    <div>
                      <p className={'text-xs inline'}>Connecting...</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <hr className={'mt-2 mb-2 opacity-30'} />
                  <div className={'flex justify-between items-center'}>
                    {token && (
                      <>
                        <p className={'font-bold text-xs'}>{token?.email}</p>
                        <div className="flex gap-2 items-center">
                          <div>
                            <Check
                              className={'inline mr-1 text-green-400'}
                              size={16}
                            />
                            <p className={'text-xs inline text-green-400'}>
                              Connected
                            </p>
                          </div>
                          <button
                            onClick={onGoogleCalenderUnSync}
                            className="text-red-500"
                          >
                            <Trash2Icon size={16} className="inline" />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <MainButton
              onClick={handleSignInClick}
              type="secondary"
              className="flex items-center justify-between w-full px-8 py-6 mt-8 bg-white border-0 rounded-full bg-opacity-10"
            >
              <div>
                <img
                  src="/google_icon.png"
                  alt="Google Icon"
                  width={30}
                  height={30}
                  className={'inline mr-1'}
                />
                <Text size="small" className={'inline'}>
                  {connecting ? `Connecting...` : `Sync Google Calendar`}
                </Text>
              </div>
            </MainButton>
          )}
        </div>
      </div>
    );
  },
);

SyncCalenderButton.displayName = 'SyncCalenderButton';

export default SyncCalenderButton;

import { Link } from 'react-router-dom';
import React, {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
  useEffect,
} from 'react';
import { ReactComponent as ClockIcon } from 'assets/images/activity/clock.svg';
import { IActivityInvite } from 'modules/activity/types/types';
import { Text } from 'components/text/primary/Text';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import AvailabilityEmptyList from './emptyList';

type ActivityInvitesProps = {
  invites: any;
};

const ActivityInvites: React.FC<ActivityInvitesProps> = ({ invites }) => {
  return (
    <div className="mt-3">
      <Text size="medium" className="py-1">
        Invites
      </Text>

      <div className="my-2.5 flex gap-2.5 flex-col">
        {invites?.length === 0 ? (
          <AvailabilityEmptyList
            text="You have not received any invites yet"
            icon={<ClockIcon />}
          />
        ) : (
          invites?.map(
            (
              invite: {
                icon: string | undefined;
                body:
                  | string
                  | number
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | ReactPortal
                  | null
                  | undefined;
                title: any;
              },
              index: Key | null | undefined,
            ) => (
              <div
                key={index}
                className="flex py-3.5 px-2.5 gap-3 justify-between bg-box rounded-md"
              >
                <div className="flex items-center flex-row gap-2">
                  <div className="flex-shrink-0">
                    {/* <img
                              src={invite.icon}
                              alt={"Profile"}
                              className="w-10 h-10 rounded-full"
                          /> */}
                    <ProfileAvatar size={45} url={invite?.icon} hideBorder />
                  </div>
                  <div className="text-sm max-w-60">{invite.body}</div>
                </div>
                <div className="flex items-center">
                  <Link
                    to={`/lets-meet/receive/${invite.title}`}
                    className="text-sm text-link underline"
                  >
                    View
                  </Link>
                </div>
              </div>
            ),
          )
        )}
      </div>
    </div>
  );
};

type InviteProps = {
  invite: IActivityInvite;
};
const Invite: React.FC<InviteProps> = ({ invite }) => {
  return (
    <div className="flex py-3.5 px-2.5 gap-3 justify-between bg-box rounded-md">
      <div className="flex items-center flex-row gap-2">
        <div className="flex-shrink-0">
          <img
            src={invite.by.photoURL}
            alt={invite.by.name}
            className="w-10 h-10 rounded-full"
          />
        </div>
        <div className="text-sm max-w-60">
          <span className="font-bold ">{invite.by.name}</span> has sent you an
          invite to meet up NOW!
        </div>
      </div>
      <div className="flex items-center">
        <Link
          to={`/lets-meet/view/${invite.id}`}
          className="text-sm text-link underline"
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default ActivityInvites;

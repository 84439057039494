import React, {
  useRef,
  useState,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileOnboardingLayout from 'modules/profile/layouts/ProfileOnboardingLayout/ProfileOnboardingLayout';
import Account from 'modules/profile/components/account/Account';
import Availability from 'modules/profile/components/availability/Availability';
import SyncCalender from 'modules/profile/components/sync/SyncCalender';
import ImportContacts from 'modules/profile/components/import/ImportContacts';
import UserAccount from 'modules/profile/components/user/UserAccount';
import { useUpdateUserMutation } from 'modules/auth/services/user.mutation.service';
import { IUser } from 'modules/auth/types/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { getNextFormIndex } from 'modules/profile/utils/utils';
import { fieldIndexTuples } from 'modules/profile/constants/profileConstant';

type FormProps = {
  setPrevOrSkip: () => void;
  update: any;
  data: IUser | undefined;
  title?: string;
  setIsSkipEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNextEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type FormComponent = ForwardRefExoticComponent<
  FormProps & RefAttributes<(() => void) | null>
>;

type FormConfig = {
  component: FormComponent;
  skip?: boolean;
  prev?: boolean;
};

const forms: FormConfig[] = [
  {
    component: Account,
    prev: true,
  },
  {
    component: Availability,
    skip: true,
    prev: true,
  },
  {
    component: SyncCalender,
    skip: true,
    prev: true,
  },
  {
    component: ImportContacts,
    skip: true,
    prev: true,
  },
  {
    component: UserAccount,
    prev: true,
  },
];

const Profile: React.FC = () => {
  const [progress, setProgress] = useState<number | null>(0);
  const [isLastStep, setLastStep] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);
  const [updateUser, { isLoading, data }] = useUpdateUserMutation();
  const formSubmitRef = useRef<(() => void) | null>(null);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const isFirstSkip = useRef(true);
  const navigate = useNavigate();
  const [isSkipEnabled, setIsSkipEnabled] = useState(true);
  const [isNextEnabled, setIsNextEnabled] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const { component: CurrentForm, skip, prev } = forms[currentFormIndex];

  const handleSkip = () => {
    if (currentFormIndex < forms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    }
  };

  const setPrevOrSkip = () => {
    if (currentFormIndex < forms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    }
  };

  const handleSubmit = () => {
    if (formSubmitRef.current) {
      formSubmitRef.current();
      console.log('handleSubmit', currentFormIndex);
      setLastStep(currentFormIndex === 4);
      if (currentFormIndex === 4) {
        setProgress(null);
      }
    }
  };

  const handlePrevious = () => {
    if (currentFormIndex > 0) {
      setCurrentFormIndex(currentFormIndex - 1);
    } else {
      navigate('/auth/create', { replace: true });
    }
  };

  useEffect(() => {
    if (user && isFirstSkip.current) {
      isFirstSkip.current = false;
      const currentSavedFormIndex = getNextFormIndex(user, fieldIndexTuples);
      setCurrentFormIndex(currentSavedFormIndex);
    }
  }, [user]);

  useEffect(() => {
    if (currentFormIndex) {
      // percentage / number of forms in onboarding * the current completed
      const currentProgress = (100 / 4) * currentFormIndex;
      setProgress(currentProgress);
    }
  }, [currentFormIndex]);

  return (
    <ProfileOnboardingLayout
      onSubmit={handleSubmit}
      progress={progress}
      {...(skip && !isLastStep && isSkipEnabled && { onSkip: handleSkip })}
      {...(prev && !isLastStep && { onPrevious: handlePrevious })}
      {...(currentFormIndex === 4 && {
        submitText: isLastStep
          ? 'I added the widget'
          : 'Start inviting friends',
      })}
      loading={isLoading}
      isLastStep={isLastStep}
      isNextEnabled={isNextEnabled}
      isSkipEnabled={isSkipEnabled}
      isPrevEnabled={currentFormIndex !== 0}
      error={error}
    >
      <CurrentForm
        key={currentFormIndex}
        setPrevOrSkip={setPrevOrSkip}
        ref={formSubmitRef}
        update={updateUser}
        data={data ?? user}
        setIsSkipEnabled={setIsSkipEnabled}
        setIsNextEnabled={setIsNextEnabled}
        setError={setError}
      />
    </ProfileOnboardingLayout>
  );
};

export default Profile;

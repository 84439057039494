import { format, isSameDay } from 'date-fns';
import { ChevronDown } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import DateSelector from 'components/dateSelector/DateSelector';
import { EventObject } from 'components/eventCardItem/EventCardItem.types';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { getMonthStartAndEnd } from 'modules/home/utils/helpers';
import { useGetEventByDateRangeQuery } from 'services/slices/eventsApiSlice';
import { DateSelectorDropDownProps } from './DateSelectorDropDown.types';

const DateSelectorDropDown = ({
  onDateChange,
  selectedValue,
}: DateSelectorDropDownProps) => {
  const toDate = useMemo(() => new Date(), []);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    selectedValue ?? undefined,
  );

  const [monthlyQueryEnabled, setMonthlyQueryEnabled] = useState(false);
  const [selectedMonthDate, setSelectedMonthDate] = useState<Date>(new Date());

  // selected dates to indicate in calendar
  const [selectedEventDates, setSelectedEventDates] = useState<Date[]>([]);

  // get the start and end of selected month for api query
  const { startOfMonth, endOfMonth } = useMemo(() => {
    return getMonthStartAndEnd(selectedMonthDate);
  }, [selectedMonthDate]);

  const {
    data: monthlyEvents,
    refetch: refetchMonthlyEvent,
    isUninitialized: isMonthlyEventsUninitialized,
  } = useGetEventByDateRangeQuery(
    {
      startDate: startOfMonth.toISOString(),
      endDate: endOfMonth.toISOString(),
    },
    { skip: !monthlyQueryEnabled },
  );

  // load monthly events to calendar indicators on first load
  useEffect(() => {
    setMonthlyQueryEnabled(true);
  }, []);

  useEffect(() => {
    if (onDateChange) {
      onDateChange(selectedDate ?? undefined);
    }
  }, [selectedDate]);

  // get dates from the events to indicate
  useEffect(() => {
    const monthEventsList = monthlyEvents?.map(
      (event: EventObject) => new Date(event?.event?.start),
    );
    setSelectedEventDates(monthEventsList ?? []);
  }, [monthlyEvents]);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <button
            className={
              'space-x-[5px] flex justify-between items-center w-auto text-base font-semibold leading-6'
            }
            style={{}}
          >
            <span className={''}>
              {selectedDate ? format(selectedDate, 'MMM') : 'Month'}
            </span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 border-none ml-3">
          <div className="w-auto flex justify-center items-center flex-col bg-black">
            <DateSelector
              selectedValues={selectedEventDates}
              onDateClick={(date) => {
                setSelectedDate((prev) => date);
              }}
              onMonthChange={(date) => {
                setSelectedMonthDate((prev) => date);
              }}
              disabledDates={(date: Date) => {
                return !selectedEventDates.some((d) => isSameDay(d, date));
              }}
            />
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateSelectorDropDown;

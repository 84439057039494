import { useEffect, useState } from 'react';

// identifies whether in a mobile view or not
function useIsMobileDevice() {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const userAgent =
      window.navigator.userAgent ||
      window.navigator.vendor ||
      (window as any).opera;
    const isMobile =
      /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(
        userAgent,
      );
    setIsMobileDevice(isMobile);
  }, []);

  return isMobileDevice;
}

export default useIsMobileDevice;

import { isMobile } from 'react-device-detect';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import NativeTimeSelector from './NativeTimeSelector';
import TimeDropdown from './TimeDropDown';
type TimeDropdownProps = {
  selectedTime?: string;
  onTimeSelect: (time: ITimeSlot) => void;
  className?: string;
  error?: boolean;

  // if startTime is provided, the dropdown will start from next 15 minutes from the provided time.
  startTime?: string;
  popupDirection?: 'up' | 'down';
  disabled?: boolean;

  // If true, the selected time will be displayed in the dropdown without any changes.
  useDefaultTime?: boolean;
  showSelectedInitially?: boolean;
};
// TryFix: Time selector issue on mobile
/*
const AvailabilityTimeSelector: React.FC<TimeDropdownProps> = (props) =>
  isMobile ? <NativeTimeSelector {...props} /> : <TimeDropdown {...props} />;
  */
const AvailabilityTimeSelector: React.FC<TimeDropdownProps> = (props) => (
  <NativeTimeSelector {...props} />
);

export default AvailabilityTimeSelector;

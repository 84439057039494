import { ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { EventFilterDropDownProps } from './EventFilterDropDown.types';

const EventFilterDropDown = ({
  onEventFilterChange,
  selectedEventType,
}: EventFilterDropDownProps) => {
  const navigate = useNavigate();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex justify-between items-center text-white text-xs font-semibold bg-[#32395E] border-none active:border-none rounded-sm px-[11px] py-1 whitespace-nowrap">
          {selectedEventType ?? 'Select Type'}{' '}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-[#202435] text-white text-left font-normal leading-normal text-xs px-[10px] border-none mt-2">
          <DropdownMenuItem
            onClick={() => {
              onEventFilterChange
                ? onEventFilterChange('All Events')
                : navigate('/home?filter=All Events');
            }}
            className="hover:cursor-pointer active:bg-[#202435] active:text-white focus:bg-[#202435] focus:text-white px-0"
            textValue="All Events"
          >
            All Events
          </DropdownMenuItem>

          <DropdownMenuSeparator className="bg-[#EBDFFF]/[0.1]" />

          <DropdownMenuItem
            onClick={() => {
              onEventFilterChange
                ? onEventFilterChange('Pending RSVPs')
                : navigate('/home?filter=Pending RSVPs');
            }}
            className="hover:cursor-pointer active:bg-[#202435] active:text-white focus:bg-[#202435] focus:text-white px-0"
            textValue="Pending RSVPs"
          >
            Pending RSVPs
          </DropdownMenuItem>

          <DropdownMenuSeparator className="bg-[#EBDFFF]/[0.1]" />

          <DropdownMenuItem
            onClick={() => {
              onEventFilterChange
                ? onEventFilterChange('Draft Events')
                : navigate('/home?filter=Draft Events');
            }}
            className="hover:cursor-pointer active:bg-[#202435] active:text-white focus:bg-[#202435] focus:text-white px-0"
            textValue="Draft Events"
          >
            Draft Events
          </DropdownMenuItem>

          <DropdownMenuSeparator className="bg-[#EBDFFF]/[0.1]" />

          <DropdownMenuItem
            onClick={() => {
              onEventFilterChange
                ? onEventFilterChange('My Events')
                : navigate('/home?filter=My Events');
            }}
            className="hover:cursor-pointer active:bg-[#202435] active:text-white focus:bg-[#202435] focus:text-white px-0"
            textValue="My Events"
          >
            My Events
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default EventFilterDropDown;

import { useLocation } from 'react-router-dom';
import { publicRoutes } from 'modules/auth/constants/constants';
import tokenUtils from 'modules/auth/utils/tokenUtils';
import { getDomainType } from 'utils/helpers';
export const useValidateAuth = () => {
  const location = useLocation();
  const domainType = getDomainType();

  let isValidAuth = false;
  const pathName = location.pathname.trim() ?? '';
  if (pathName === '/auth/login') {
    // Clear tokens if user is on login page
    // tokenUtils.clearTokens();
  } else if (pathName == '/auth/start') {
    // start page
    const tokensPresent = tokenUtils.getRefreshToken();
    if (tokensPresent) {
      isValidAuth = true;
    }
  } else {
    const tokensPresent = tokenUtils.getRefreshToken();
    const pathSplit = location.pathname?.split('/') ?? [];
    const isPublicRoute =
      pathSplit.length > 1 && publicRoutes.includes(pathSplit[1]);
    if (tokensPresent) {
      isValidAuth = true;
    } else {
      if (!isPublicRoute && domainType !== 'landing') {
        window.location.href = '/auth/start';
      } else {
        isValidAuth = true;
      }
    }
  }

  return { isValidAuth: isValidAuth };
};

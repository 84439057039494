import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { isArray, isEmpty, isString } from 'lodash';
import vCard from 'vcf';
import { Text } from 'components/text/primary/Text';
import FileUpload from 'components/upload/FileUpload';
import {
  mergeContacts,
  transformVCFToContact,
} from 'modules/profile/utils/utils';
import { Contact } from 'modules/profile/types/types';
import {
  androidInstructions,
  iphoneInstructions,
} from 'modules/profile/constants/common';
import { IUser } from 'modules/auth/types/types';
import { SecondaryText } from 'components/text/secondary/Text';
import { ReactComponent as ContactIcon } from 'assets/images/import/contacts-icon.svg';
import MainButton from 'modules/auth/components/MainButton';
import { toast } from 'components/ui/toast/use-toast';
import MobileInstructionsTabs from './UploadInstructions/MobileInstructionsTabs';

type ImportContactsProps = {
  setPrevOrSkip: () => void;
  update?: any;
  data?: IUser | undefined;
};

const ImportContacts = forwardRef<(() => void) | null, ImportContactsProps>(
  ({ setPrevOrSkip, update, data }, ref) => {
    const existingContacts = (data?.contacts as any) ?? [];
    const [loadingContacts, setContactsLoading] = useState<boolean>(false);
    const [importedContacts, setImportedContacts] = useState<Contact[]>([]);
    const [fileError, setImportError] = useState<string | null>(null);
    const [showSkipError, setShowSkipError] = useState(false);

    const handleSubmit = () => {
      onSubmitForm(importedContacts);
    };

    useImperativeHandle(ref, () => handleSubmit);

    const handleFileChange = (file: File | null) => {
      setShowSkipError(false);
      setImportedContacts([]);
      if (file) {
        setContactsLoading(true);
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const fileContent = event.target?.result as string;
            if (!fileContent) {
              throw new Error('File content is empty.');
            }
            const vCardArray = vCard.parse(fileContent);
            const batchSize = 10;

            const contacts: Contact[] = [];
            const processBatch = (startIndex: number) => {
              const endIndex = Math.min(
                startIndex + batchSize,
                vCardArray.length,
              );
              const batch = vCardArray?.slice(startIndex, endIndex);
              const transformedContacts = batch
                ?.map((card: any) => {
                  const contact = transformVCFToContact(card?.data);
                  return !isEmpty(contact) ? contact : null;
                })
                .filter((contact): contact is Contact => contact !== null)
                .flat();

              contacts.push(...transformedContacts);

              if (endIndex < vCardArray.length) {
                setTimeout(() => processBatch(endIndex), 0);
              } else {
                setContactsLoading(false);
                setContactsLoading(false);

                if (contacts.length === 0) {
                  toast({
                    title: 'No new contacts found.',
                  });
                  return;
                }

                setImportedContacts(contacts);
              }
            };

            processBatch(0);
          } catch (error) {
            console.error('Error parsing VCF file:', error);
            setImportError('Error Importing contacts');
            setContactsLoading(false);
          }
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          setContactsLoading(false);
          setImportedContacts([]);
        };
        reader.readAsText(file);
      } else {
        console.log('No file selected');
        clearContacts();
      }
    };

    const onSubmitForm = async (contacts: Contact[] | null) => {
      if (isEmpty(contacts)) {
        setShowSkipError(true);
        return;
      }
      try {
        const updateData = {
          contacts: contacts,
        };
        const response = await update({ data: updateData }).unwrap();
        if (response) {
          setPrevOrSkip();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const clearContacts = () => {
      setImportedContacts([]);
      setImportError(null);
    };

    useEffect(() => {
      if (!isEmpty(existingContacts)) {
        if (isString(data)) {
          setImportedContacts(JSON.parse(existingContacts) ?? []);
        } else if (isArray(data)) setImportedContacts(existingContacts);
      }
    }, [existingContacts]);

    return (
      <>
        <div className="relative flex flex-col items-center w-full h-full gap-2">
          <div className="flex flex-col w-full gap-1">
            <Text
              size="small"
              className="mb-3 font-sofia-extralight opacity-85"
            >
              * You can skip this option and add contacts manually if required
              through settings
            </Text>
            <Text size="large">Import your contacts</Text>
            <Text size="small" className="font-sofia-extralight">
              For a better experience, import your contacts so Caleido can send
              out your invites for you!
            </Text>
          </div>
          <div className="w-full my-4">
            {isEmpty(importedContacts) ? (
              <FileUpload
                loading={loadingContacts}
                onFileChange={handleFileChange}
                error={fileError}
                setError={setImportError}
                accept={{
                  'text/x-vcard': ['.vcf'],
                }}
              />
            ) : (
              <MainButton
                type="secondary"
                icon={
                  <Text
                    size="small"
                    className="text-purple-200 underline cursor-pointer decoration-solid"
                    onClick={(e) => {
                      e.stopPropagation();
                      clearContacts();
                    }}
                  >
                    Reupload
                  </Text>
                }
                className="flex items-center justify-between w-full px-8 py-6 bg-white border rounded-full border-opacity-20 bg-opacity-10 border-slate-300"
              >
                <Text
                  size="small"
                  className="inline-flex items-center justify-start gap-2 font-normal truncate"
                >
                  <ContactIcon />
                  {`Uploaded ${importedContacts?.length} contacts`}
                </Text>
              </MainButton>
            )}
          </div>
          <MobileInstructionsTabs
            title={
              <>
                <Text size="small" className="inline-flex items-baseline gap-2">
                  How to upload contacts to
                  <SecondaryText size="small" className="text-lg">
                    Caleido?
                  </SecondaryText>
                </Text>
              </>
            }
            iphoneInstructions={iphoneInstructions}
            androidInstructions={androidInstructions}
          />
        </div>
        {showSkipError && (
          <Text size="small" type="error">
            {`Want to skip this step? press "Skip" on the right corner`}
          </Text>
        )}
      </>
    );
  },
);

ImportContacts.displayName = 'ImportContacts';

export default ImportContacts;

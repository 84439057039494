import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  CountryCode,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import TextField from 'components/textfield/TextField';
import { ContactActionProps } from 'modules/settings/types/types';
import { Text } from 'components/text/primary/Text';
import { ReactComponent as DeleteIcon } from 'assets/images/profile/delete-icon.svg';
import { ReactComponent as TelIcon } from 'assets/images/common/phone-icon.svg';
import MainButton from 'modules/auth/components/MainButton';
import { Contact } from 'modules/profile/types/types';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import countryCodes from 'data/country-codes.json';
import { ReactComponent as Logo } from 'assets/images/profile/caleido-logo.svg';

import ContactActions from 'modules/settings/enums/enum';
import ReceiveCountryCodeDropdown from 'modules/letsMeet/components/meetResponse/countryCodeInput';
import { getPrefixByCountryCode } from 'modules/auth/utils/stringUtils';

type FormValues = {
  name: string;
  phoneNumber: string;
  code: string;
};

const sanitizePhoneNumber = (phoneNumber: string): string => {
  // Remove non-numeric characters
  return phoneNumber.replace(/\D/g, '');
};

const AddContact = ({
  initialContacts,
  handleContactsChange,
  setContactAction,
  contact,
  loading,
  isEdit,
  isView,
  isAdd,
  deleteContact,
}: ContactActionProps) => {
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<FormValues>();

  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('+1');
  const [error, setError] = useState<string | undefined>(undefined);

  const [displayNumber, setDisplayNumber] = useState<string>('');

  const onSubmitForm: SubmitHandler<FormValues> = async (data) => {
    if (!phone || !code) return setError('Phone number is required');

    const num = parsePhoneNumber(
      phone,
      getPrefixByCountryCode(code) as CountryCode,
    );

    if (!num.isValid()) return setError('Invalid phone number');

    const sanitizedData = {
      ...data,
      phoneNumber: num.number,
    };

    if (isEdit) {
      const exists = initialContacts?.find(
        (c) =>
          sanitizePhoneNumber(c.phoneNumber)?.trim() ===
            sanitizePhoneNumber(sanitizedData.phoneNumber)?.trim() &&
          c._id !== contact?._id,
      );

      if (exists) {
        setError('Contact already exists');
        return;
      }

      const updatedContacts = initialContacts?.map((c) => {
        if (c.phoneNumber === `+${contact?.phoneNumber}`) {
          return sanitizedData;
        }
        return c;
      }) as Contact[];

      handleContactsChange(updatedContacts);
    } else {
      // check if contact already exists
      const exists = initialContacts?.find(
        (c) =>
          sanitizePhoneNumber(c.phoneNumber)?.trim() ===
          sanitizePhoneNumber(sanitizedData.phoneNumber)?.trim(),
      );

      if (exists) {
        setError('Contact already exists');
        return;
      }

      const updatedContacts = [...initialContacts, sanitizedData] as Contact[];
      handleContactsChange(updatedContacts);
    }
  };

  const onContactDelete = async (data: Contact) => {
    if (loading) return;

    console.log('Delete contact', data);
    // Remove the contact from the list by _id
    const updatedContacts = initialContacts?.filter(
      (c) => c._id !== data._id,
    ) as Contact[];

    deleteContact?.(data.phoneNumber);
    console.log('Updated contacts', updatedContacts);
    // handleContactsChange(updatedContacts);
  };

  useEffect(() => {
    if (contact && isEdit) {
      reset({
        name: contact.name || '',
        phoneNumber: contact.phoneNumber || '',
      });
    }
  }, [reset, contact, isEdit]);

  useEffect(() => {
    if (isAdd) {
      setPhone('');
      setCode('+1');
    }

    if (contact && !isEdit) {
      const num = parsePhoneNumberFromString(`+${contact.phoneNumber}`);
      setPhone(num?.nationalNumber ?? '');
      setCode(`+${num?.countryCallingCode ?? ''}`);
    }

    if (contact && isView) {
      const num = parsePhoneNumberFromString(`+${contact.phoneNumber}`);
      if (num) {
        const formatted = parsePhoneNumber(num.number, num.country);
        setDisplayNumber(formatted.formatInternational());
      }
    }
  }, [contact, isEdit, isView, isAdd]);

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2 px-6 py-4 my-6">
      {isView ? (
        <div className="flex flex-col items-center justify-center w-full gap-2">
          <div className="flex justify-end w-full">
            <DeleteIcon
              className="mx-2 cursor-pointer"
              onClick={() => onContactDelete(contact!)}
            />
          </div>
          <div className="flex items-center w-full gap-4 px-4 py-2 mb-2">
            <div className="relative">
              <ProfileAvatar
                size={40}
                url={
                  contact
                    ? contact.userId
                      ? `${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`
                      : undefined
                    : undefined
                }
                hideBorder
              />
              {contact?.userId && (
                <div className="absolute bg-white rounded-full p-1 right-0 -bottom-1.5 z-20">
                  {<Logo height={'12px'} width={'12px'} />}
                </div>
              )}
            </div>
            <Text size="small" className="font-semibold text-center truncate">
              {contact?.name}
            </Text>
          </div>
          <div className="flex items-center justify-start w-full gap-4 px-4 py-6 border-y border-opacity-20 border-slate-300">
            <TelIcon className="m-2" />
            <div className="flex flex-col gap-1">
              <Text size="small" className="font-bold truncate text-md">
                {displayNumber}
              </Text>
              <Text size="small" className="text-sm font-normal">
                Contact Number
              </Text>
            </div>
          </div>
          {contact?.userId === '' || contact?.userId == undefined ? (
            <MainButton
              type="primary"
              className="w-48 mt-6"
              onClick={() => setContactAction(ContactActions.EDIT_CONTACTS)}
              loading={loading}
              disabled={loading}
            >
              <Text size="small" className="w-full text-center">
                Edit Contact
              </Text>
            </MainButton>
          ) : null}
        </div>
      ) : (
        <>
          <Text size="medium" className="w-full">
            {isEdit ? 'Edit Contact' : 'Add Contact'}
          </Text>
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="flex flex-col items-center justify-center w-full"
          >
            <TextField
              label="Name"
              placeholder="Enter contact name"
              {...register('name', { required: 'Contact name is required' })}
              required
              error={errors.name?.message}
            />

            <div className="flex  flex-col w-full my-2">
              <div className="text-sm text-white flex w-full justify-start space-x-2 my-2">
                <div>Phone Number</div>
                <div>*</div>
              </div>
              <ReceiveCountryCodeDropdown
                onCodeSelect={(code) => {
                  setCode(code);
                  setError(undefined);
                }}
                onPhoneNumberChange={(number) => {
                  setPhone(number);
                  setError(undefined);
                }}
                countryCodes={countryCodes}
                selectedCode={code}
                phoneNumber={phone}
              />
              {error && (
                <div className="font-sans text-xs text-red-500 mt-1 5">
                  {error}
                </div>
              )}
            </div>
            <MainButton
              type="primary"
              className="w-48 mt-2"
              onClick={handleSubmit(onSubmitForm)}
              loading={loading}
              disabled={loading}
            >
              <Text size="small" className="w-full text-center">
                {isEdit ? 'Update Contact' : 'Add Contact'}
              </Text>
            </MainButton>
          </form>
        </>
      )}
    </div>
  );
};

export default AddContact;
